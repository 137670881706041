import React, { useEffect, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const Header = ({ totalItems }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [itemCount, setItemCount] = useState(totalItems);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSignOut = () => {
    localStorage.removeItem("user");
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const count = localStorage.getItem("totalItems");
    if (count) {
      setItemCount(Number(count));
    } else {
      setItemCount(0); 
    }
    const userDetails = localStorage.getItem("user");
    setIsLoggedIn(!!userDetails);
  }, [totalItems]);

  const handleWhatsAppClick = () => {
    const phoneNumber = "7600483333"; 
    const message = "Hello!Can I get More Information about Product."; 
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank"); 
  };

  return (
    <div className="bg-black text-white  font-varela md:px-24 px-6 ">
      <nav className="container flex justify-between items-center mb-5">
        <div style={{ fontFamily: "cursive" }} className="mt-4">
          <a href="/" className="md:text-3xl sm:text-xs font-bold uppercase">
            <img
              src="/logo1.png"
              alt="/"
              className="w-64"
              width="64%"
             
            />
          </a>
        </div>

        <div className="hidden md:block mt-4">
          <ul className="flex gap-1">
            <li>
              <a
                href="/about"
                className="inline-block text-sm py-2 px-7 uppercase text-[15px] relative z-10 custom-link"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/earbuds"
                className="inline-block text-sm py-2 px-7 uppercase text-[15px] relative z-10 custom-link"
              >
                Earbuds
              </a>
            </li>

            <li>
              <a
                href="/cable"
                className="inline-block text-sm py-2 px-7 uppercase text-[15px] relative z-10 custom-link"
              >
                Cable
              </a>
            </li>
            <li>
              <a
                href="/charger"
                className="inline-block text-sm py-2 px-7 uppercase text-[15px] relative z-10 custom-link"
              >
                Charger
              </a>
            </li>

            <li>
              <a
                href="/contact"
                className="inline-block text-sm py-2 px-3 uppercase relative z-10 custom-link"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="flex items-center space-x-4 mt-4">
        
          <a href="/cart" alt="/">
            <div className="relative py-2">
              <div className="absolute -top-1 -right-2 flex items-center justify-center w-5 h-5 bg-red-500 text-white text-xs font-semibold rounded-full">
                {itemCount}
              </div>
              <ShoppingCartIcon className="text-white text-3xl" />
            </div>
          </a>

          <div className="md:hidden md:order-1 order-2">
            <button onClick={toggleMenu}>
              {menuOpen ? (
                <svg
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="text-4xl pt-2"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M18.364 5.636l-1.414-1.414L12 9.172 7.05 4.222 5.636 5.636 10.586 10.586 5.636 15.536l1.414 1.414L12 12.828l4.95 4.95 1.414-1.414-4.95-4.95 4.95-4.95z"></path>
                </svg>
              ) : (
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="text-4xl pt-2"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>
      <nav className="container flex justify-center items-center">
        <div className="flex items-center space-x-4"></div>
      </nav>

      {menuOpen && (
        <div className="md:hidden absolute w-full bg-black z-50 left-0">
          <ul className="flex flex-col gap-4 mb-4 mx-5">
            <li>
              <div>
                <a
                  href="/about"
                  className="inline-block text-sm py-2 px-3 uppercase border-b-2 border-transparent hover:border-red-600 transition-all duration-300 "
                >
                  About
                </a>
              </div>
            </li>
            <li>
              <div>
                <a
                  href="/earbuds"
                  className="inline-block text-sm py-2 px-3 uppercase border-b-2 border-transparent hover:border-red-600 transition-all duration-300"
                >
                  Earbuds
                </a>
              </div>
            </li>
            <li>
              <div>
                <a
                  href="/cable"
                  className="inline-block text-sm py-2 px-3 uppercase border-b-2 border-transparent hover:border-red-600 transition-all duration-300"
                >
                  Cable
                </a>
              </div>
            </li>
            <li>
              <div>
                <a
                  href="/charger"
                  className="inline-block text-sm py-2 px-3 uppercase border-b-2 border-transparent hover:border-red-600 transition-all duration-300"
                >
                  Charger
                </a>
              </div>
            </li>

            <li>
              <div>
                <a
                  href="/contact"
                  className="inline-block text-sm py-2 px-3 uppercase border-b-2 border-transparent hover:border-red-600 transition-all duration-300"
                >
                  Contact
                </a>
              </div>
            </li>
          </ul>
        </div>
      )}

      <div
        className="text-3xl text-white bg-green-700 p-2 rounded-full fixed bottom-10 right-10 hover:rotate-[360deg] duration-500 z-[99999] "
        onClick={handleWhatsAppClick}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
        </svg>
      </div>
      <div className="sticky-icon" style={{ zIndex: 2 }}>
        <a
          href="https://www.instagram.com/makeityours1010/"
          className="Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-instagram"></i>Instragram{" "}
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61567169340324"
          className="Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-f"> </i> Facebook{" "}
        </a>

        <a
          href="https://whatsapp.com/.com/"
          className="Watshapp"
          target="_blank"
          rel="noreferrer"
          onClick={handleWhatsAppClick}
        >
          <i className="fa-brands fa-whatsapp"> </i> WhatsApp{" "}
        </a>
        <a
          href="tel:+917600483333"
          className="Phone"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-solid fa-phone"></i> Call Now{" "}
        </a>
      </div>
    </div>
  );
};

export default Header;