import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
const [resetToken, setResetToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
 const handleResetPassword = async (e) => {
    e.preventDefault();
 if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
try {
      await axios.post(
        "https://customizedapi.onrender.com/register/reset-password",
        {
          token: resetToken,
          password,
          confirmPassword,
        }
      );
      alert("Password has been reset successfully.");
      navigate("/login");
    } catch (error) {
      console.error("Error resetting password:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        alert(
          `Failed to reset password. ${
            error.response.data.message || "Please try again later."
          }`
        );
      } else {
        alert("Failed to reset password. Please try again.");
      }
    }
  };
const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };
return (
    <div>
      <div className="p-5 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-lg font-semibold mb-4">Reset Password</h2>
          <form onSubmit={handleResetPassword}>
            <div>
              <label
                htmlFor="reset-token"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Reset Token
              </label>
              <input
                type="text"
                id="reset-token"
                value={resetToken}
                onChange={(e) => setResetToken(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter reset token"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                New Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter new password"
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-2 top-2.5 text-black hover:text-gray-800"
                >
                  {showPassword ? (
                    <i className="fa-solid fa-eye"></i>
                  ) : (
                    
                    <i className="fa-solid fa-eye-slash"></i> 
                  )}
                </button>
              </div>
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Confirm new password"
                  required
                />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute right-2 top-2.5 text-black hover:text-gray-800"
                >
                  {showConfirmPassword ? (
                    <i className="fa-solid fa-eye"></i> 
                  ) : (
                    <i className="fa-solid fa-eye-slash"></i> 
                  )}
                </button>
              </div>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-black hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
