import React from 'react';

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="container mx-auto text-center">
        <div className="row">
          <div className="col-12">
            <img
              src="./PageNotFound.png"
              alt="Page Not Found"
              className="w-full h-auto max-h-96 object-contain"
            />
          </div>
        </div>
        <h2 className="mt-4 text-xl font-bold">Oops! Page Not Found.</h2>
      </div>
    </div>
  );
};

export default PageNotFound;
