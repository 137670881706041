import React from "react";
import { Helmet } from "react-helmet";
const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>About Make It Yours – Leading Custom Tech Accessories</title>
        <meta
          name="description"
          content="Discover Make It Yours, creating custom earbuds, chargers, and cables across Kerala, Karnataka, Tamil Nadu, Mumbai, Bengaluru, Jaipur, Delhi, Hyderabad, Ahmedabad, Gujarat."
        />
        <meta
          name="keywords"
          content="Leading custom tech accessories brand in Kerala and Karnataka | Top custom earbuds and chargers in Tamil Nadu and Mumbai | Discover personalized tech accessories in Bengaluru and Jaipur | Best custom cables and chargers available in Delhi and Hyderabad | Premier custom tech accessories store in Ahmedabad and Gujarat | High-quality custom earbuds and chargers in Kerala and Karnataka | Personalized tech accessories for Tamil Nadu and Mumbai | Custom cables and chargers provider in Bengaluru and Jaipur | Top-rated custom tech accessories in Delhi and Hyderabad | Make It Yours: Custom earbuds, chargers, and cables in Ahmedabad and Gujarat"
        />
          <link rel="canonical" href="https://makeitur.com/about" />
      </Helmet>
      <div className="md:p-12 p-4">
        <p className="font-bold text-2xl">Welcome to Make It Yours.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 ">
          <div>
            <p className="pt-4 ">
              At Make It Yours, we take pride in crafting high-quality,
              customized earbuds, chargers, and cables that perfectly fit your
              needs. Our journey began with a simple idea: to revolutionize the
              way people experience technology accessories. We are committed to
              delivering products that not only enhance your daily life but also
              reflect your personal style.
            </p>
            <p className="pt-4">
              Make It Yours emerged from a passion for blending technology with
              personalization. What started as a small workshop has now grown
              into a leading brand in the industry. Our team of dedicated
              professionals is driven by innovation, creativity, and a deep
              understanding of customer needs.
            </p>
            <p className="pt-4">
              We believe in the power of customization. Whether it’s designing
              earbuds that provide superior sound quality, creating chargers
              that ensure fast and reliable power delivery, or producing cables
              that are durable and stylish, we put our heart into every product
              we make.
            </p>
          </div>
          <div className="flex justify-center ">
            <video
              src="./homevideo.mp4"
              autoPlay
              muted
              controls
              loop
              className="w-full h-full"
              alt="AboutPage Video"
              playsInline
              style={{ pointerEvents: "none" }}
            ></video>
          </div>
        </div>
      </div>
      <p className="text-center font-bold text-3xl">Why Choose Us?</p>
      <div className="md:grid-cols-4 grid-cols-1 grid gap-8 m-12">
        <div className="bg-gray-200 shadow-lg">
          <p className="font-bold text-center p-4">Quality Craftsmanship</p>
          <p className="p-3">
            Every product is meticulously crafted to ensure it meets our high
            standards of quality.
          </p>
        </div>
        <div className="bg-gray-200 shadow-lg">
          <p className="font-bold text-center p-4">Customizability</p>
          <p className="p-3">
            Tailor our products to fit your personal style and functional needs.
          </p>
        </div>
        <div className="bg-gray-200 shadow-lg">
          <p className="font-bold text-center p-4">Customer-Centric Approach</p>
          <p className="p-3">
            We value your feedback and strive to improve our products and
            services continuously.
          </p>
        </div>
        <div className="bg-gray-200 shadow-lg">
          <p className="font-bold text-center p-4">Sustainability</p>
          <p className="p-3">
            We are committed to environmentally friendly practices and
            sustainable materials.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
