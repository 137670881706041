import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiDeleteBinLine } from "react-icons/ri";


const Cart = ({ onUpdateTotalItems }) => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cart);
    onUpdateTotalItems(cart);
  }, [onUpdateTotalItems]);

  const handleRemoveItem = (id) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    onUpdateTotalItems(updatedCart);
  };

  const handleQuantityChange = (id, change) => {
    const updatedCart = cartItems.map((item) => {
      if (item.id === id) {
        const updatedQuantity = item.quantity + change;
        if (updatedQuantity > 0) {
          return { ...item, quantity: updatedQuantity };
        }
      }
      return item;
    });

    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleCheckout = () => {
    navigate("/checkout");
    window.scroll(0, 0);
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const additionalCosts = 0; // Replace this with any calculation for additional costs
  const totalAmount = totalPrice + additionalCosts;

  return (
    <div className="bg-gray-100 py-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="container mx-auto md:px-4 px-2">
            {cartItems.length > 0 ? (
              <div className="bg-white rounded-lg shadow-md md:p-4 p-2">
                <div className="flex justify-between border-b p-1 px-3">
                  <h1 className="text-lg font-semibold mb-4">Your Cart</h1>
                </div>

                {cartItems.map((item) => (
                  <div
                    key={item.id}
                    className="flex flex-col md:flex-row justify-between items-center border-b p-5 gap-4"
                  >
                    {/* Product Name and Image */}
                    <div className="flex items-center w-full md:w-1/2">
                      <img
                        className="h-20 w-20 mr-4"
                        src={item.photo}
                        alt={item.productName}
                      />
                      <div>
                        <h2 className="font-semibold">{item.productName}</h2>
                        <p className="text-sm">Color: {item.color}</p>
                        {item.logoName && (
                          <p className="text-sm">Logo Name: {item.logoName}</p>
                        )}
                      </div>
                    </div>
                    <p className="text-lg">₹{item.price}</p>

                    <div className="flex flex-col md:flex-row items-center w-full md:w-1/2 justify-end gap-4">
                      {/* Quantity Control */}
                      <div className="flex items-center border-black border">
                        <button
                          className=" text-black px-2 py-1"
                          onClick={() => handleQuantityChange(item.id, -1)}
                        >
                          -
                        </button>
                        <p className="text-sm mx-4">{item.quantity}</p>
                        <button
                          className=" text-black px-2 py-1"
                          onClick={() => handleQuantityChange(item.id, 1)}
                        >
                          +
                        </button>
                      </div>

                      {/* Quantity Text */}
                      <p className="text-sm mx-4">
                        Quantity: <span>{item.quantity}</span>
                      </p>

                      {/* Remove Button */}
                      <button
                        className=" py-1 px-2"
                        onClick={() => handleRemoveItem(item.id)}
                      >
                        <RiDeleteBinLine
                        className="text-red-500 h-6 w-6" />
                      </button>
                    </div>
                  </div>
                ))}

                <div className="flex justify-between items-center p-5 pt-4">
                  <h2 className="text-lg font-semibold">Subtotal:</h2>
                  <span className="text-lg">₹{totalPrice.toFixed(2)}</span>
                </div>

                {/* Total Section */}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-4">
                <div className="text-center">
                  <img
                    src="https://aleointernational.com/img/empty-cart-yellow.png"
                    alt="Empty cart"
                    className="mb-4"
                  />
                </div>
                <div className="text-center">
                  <a href="/earbuds" alt="Homepage">
                    <button className="bg-yellow-500 p-2 text-black">
                      Back To Shopping
                    </button>
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Order Summary Section */}
          {cartItems.length > 0 && (
            <div className="bg-white p-4 shadow-md rounded-lg w-full lg:w-1/3">
              <div className="flex justify-between border-b p-1 px-3">
                <p className="text-lg font-semibold mb-4">Order Summary</p>
              </div>

              <div className="flex justify-between mb-2 mt-5">
                <p className="text-lg font-medium">Subtotal:</p>
                <p className="text-lg font-medium">₹{totalPrice.toFixed(2)}</p>
              </div>

              <div className="flex justify-between mb-2">
                <p className="text-lg font-medium">Total:</p>
                <p className="text-lg font-medium">₹{totalAmount.toFixed(2)}</p>
              </div>

              <div className="w-full mt-12">
                <button
                  className="border-black border py-2 px-4"
                  onClick={handleCheckout}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Cart;