import React, { useEffect, useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import "aos/dist/aos.css";
import AOS from "aos";
import { FaPenAlt } from "react-icons/fa";


const Earbuds = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("J171-3D"); // Set default to J171-3D
  const [selectedRatings, setSelectedRatings] = useState({}); // Store selected ratings
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://customizedapi.onrender.com/product/get"
        );

        if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data;
          const earbudsProducts = data.filter(
            (product) => product.category === "Earbuds"
          );
          setProducts(earbudsProducts);

          const initialFilteredProducts = earbudsProducts.filter((product) =>
            product.productName.toLowerCase().includes("j171-3d".toLowerCase())
          );
          setFilteredProducts(initialFilteredProducts);
        } else {
          console.error("Invalid API response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching the products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleFilterProducts = (subcategory) => {
    setSelectedSubcategory(subcategory);
    if (subcategory === "") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.productName.toLowerCase().includes(subcategory.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const handleAddToCart = (product) => {
    const productId = product.id || product._id; // Ensure you use the correct id property

    if (productId) {
      // Navigate to the product page with the product ID in the URL
      navigate(`/product/gaming-earbuds-j171-3d/${productId}`); // Only the productId is sent in the URL
      window.scrollTo(0, 0);
    } else {
      console.error("Product ID is undefined. Cannot navigate to cart.");
    }
  };

  const getStarRating = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <FaStar key={index} className="text-yellow-500" />
          ))}
        {halfStar && <FaStarHalfAlt className="text-yellow-500" />}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <FaRegStar key={index} className="text-yellow-500" />
          ))}
      </>
    );
  };

  const handleRatingClick = (productId, rating) => {
    setSelectedRatings((prev) => ({ ...prev, [productId]: rating }));
  };

  return (
    <div>
      <Helmet>
        <title>
          Custom Earbuds – Personalized Sound & Style | Make It Yours
        </title>
        <meta
          name="description"
          content="Experience premium custom earbuds from Make It Yours. Superior sound, tailored designs for comfort, shipped across India including Kerala, Gujarat, Mumbai, Bengaluru, and Delhi."
        />
        <meta
          name="keywords"
          content="Personalized custom earbuds with superior sound in Kerala and Gujarat | Premium custom earbuds for comfort and style in Mumbai and Bengaluru | Best custom earbuds shipped across India including Delhi and Kerala | High-quality personalized earbuds for Gujarat and Bengaluru | Tailored sound and design custom earbuds in Mumbai and Delhi | Top-rated custom earbuds with comfort features in Kerala and Gujarat | Buy premium custom earbuds online in Bengaluru and Mumbai | Superior sound custom earbuds delivered to Delhi and Kerala | Personalized earbuds with stylish design for Gujarat and Bengaluru | Custom earbuds for enhanced listening experience in Mumbai and Delhi"
        />
        <link rel="canonical" href="https://makeitur.com/earbuds" />
      </Helmet>
      <section>
        <img
          src="./earbudsbanner.png"
          alt="/"
          className="w-full h-48 sm:h-64 md:h-96"
        />
      </section>
      <section>
        <div className="container-fluid md:pl-16 md:pr-16 pl-3 pr-3 mx-auto py-8 bg-gray-100">
          <h1 className="md:text-3xl text-xl font-bold text-center mb-8">
          Customized Earbuds Collection
          </h1>
          <div className="flex flex-wrap gap-2 sm:gap-3 mb-4 sm:mb-6">
            <button
              className={`px-3 sm:px-4 py-1 sm:py-2 rounded ${
                selectedSubcategory === ""
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("")}
            >
              All
            </button>
            <button
              className={`px-3 sm:px-4 py-1 sm:py-2 rounded ${
                selectedSubcategory === "W90-CP"
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("W90-CP")}
            >
              W90-CP - 3D Earbuds
            </button>
            <button
              className={`px-3 sm:px-4 py-1 sm:py-2 rounded ${
                selectedSubcategory === "B93"
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("B93")}
            >
              B93 - 3D Earbuds
            </button>
            <button
              className={`px-3 sm:px-4 py-1 sm:py-2 rounded ${
                selectedSubcategory === "b97"
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("b97")}
            >
              b97 - Gaming 3D Earbuds
            </button>
            <button
              className={`px-3 sm:px-4 py-1 sm:py-2 rounded ${
                selectedSubcategory === "J171-3D"
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("J171-3D")}
            >
              J171-3D - Gaming 3D Earbuds
            </button>
          </div>
          <div className="grid md:grid-cols-4 md:gap-8 grid-cols-2 gap-4">
  {filteredProducts.length > 0 ? (
    filteredProducts.map((product, index) => {
      const isOutOfStock = false; // Set this to false to always show "Customized" and "Buy Now"

      return (
        <div key={product._id} className="relative bg-white shadow-md rounded-lg overflow-hidden">
          {/* Always display the Customized label */}
          <span className="flex gap-1 absolute top-1 right-0 bg-gray-200 text-black md:text-sm text-xs font-bold py-1 px-2 ">
            Customized <FaPenAlt />
          </span>

          {/* Clickable Image */}
          <div className="cursor-pointer pt-7 md:pt-4" onClick={() => handleAddToCart(product)}>
            <img
              src={product.photo}
              alt={product.title}
              className="h-32 sm:h-48 w-48 sm:w-60 mx-auto object-contain transform transition duration-300 hover:scale-95"
            />
          </div>

          <div className="md:p-2 p-1">
            <p className="text-xl line-clamp-2 text-center">
              <span className="text-lg font-bold">
                {product.productName}
              </span>
            </p>
            <p className="text-sm text-gray-500 text-center">
              {product.description ? (
                <>
                  <span className="block sm:hidden">
                    {`${product.description.slice(0, 40)}...`}
                  </span>
                  <span className="hidden sm:block md:hidden">
                    {`${product.description.slice(0, 60)}...`}
                  </span>
                  <span className="hidden md:block">
                    {`${product.description.slice(0, 80)}...`}
                  </span>
                </>
              ) : (
                "No description available."
              )}
            </p>

            {/* Star Rating */}
            <div className="flex items-center justify-center space-x-1 text-center pt-1">
              {Array.from({ length: 5 }, (_, index) => (
                <span
                  key={index}
                  onClick={() => handleRatingClick(product._id, index + 1)}
                  className={`cursor-pointer ${
                    selectedRatings[product._id] && index < selectedRatings[product._id]
                      ? "text-yellow-500"
                      : "text-gray-400"
                  }`}
                >
                  <FaStar />
                </span>
              ))}
            </div>

            <p className="text-lg text-center pt-1">
              <span className="text-sm text-gray-500 line-through ">
                ₹{product.price}
              </span>
              <span className="text-base font-semibold ml-4">
                ₹{product.discountPrice}
              </span>
            </p>

            <div className="flex justify-center">
              <button
                className={`rounded border border-black ${
                  isOutOfStock ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-black text-white hover:bg-white hover:text-black hover:border"
                } w-full p-2 font-bold shadow-lg`}
                onClick={() => handleAddToCart(product)}
                disabled={isOutOfStock} // still keep the button disabled if you want to handle stock logic in the future
              >
                {isOutOfStock ? "Out of Stock" : <><AddShoppingCartIcon /> Buy Now</>}
              </button>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <p className="col-span-full text-center text-gray-500">
      No products found in this category.
    </p>
  )}
</div>





        </div>
      </section>
    </div>
  );
};

export default Earbuds;
