import React, { useEffect,useState } from "react";
import "animate.css/animate.min.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { FaPenAlt } from "react-icons/fa";
const Home = () => {
  const settings = {
    dots: false,
    autoplay:true,
    autoplaySpeed: 2000,      
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    adaptiveHeight: true,
  };
  const sliderImages = ["./slider3.png", "./slider2.png", "./slider1.png"];
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState("J171-3D");
  const [selectedRatings, setSelectedRatings] = useState({});
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://customizedapi.onrender.com/product/get"
        );
        const productArray = response.data.data;
        if (productArray) {
          setProducts(productArray);
        } else {
          setError("No products found");
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Error fetching data");
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);
 
  const getStarRating = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <FaStar key={index} className="text-yellow-500" />
          ))}
        {halfStar && <FaStarHalfAlt className="text-yellow-500" />}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <FaRegStar key={index} className="text-yellow-500" />
          ))}
      </>
    );
  };
 const handleRatingClick = (productId, rating) => {
    setSelectedRatings((prev) => ({ ...prev, [productId]: rating }));
  };
  const handleAddToCart = (product) => {
    const productId = product.id || product._id;
    if (productId) {
      navigate(`/product/gaming-earbuds-j171-3d/${productId}`);
      window.scrollTo(0, 0);
    } else {
      console.error("Product ID is undefined. Cannot navigate to cart.");
    }
  };
  const services = [
    {
      imgSrc: "/equalizer.png",
      title: "High Quality Sound",
      description: "Experience crystal clear audio with every beat.",
    },
    {
      imgSrc: "/charging.png",
      title: "Long Battery Life",
      description: "Enjoy up to 20 hours of playtime on a single charge.",
    },
    {
      imgSrc: "/delivery-truck.png",
      title: "Fast Delivery",
      description: "Quick delivery to get your products when you need them.",
    },
    {
      imgSrc: "/notes.png",
      title: "Customized Product",
      description: " creating a unique product that reflects your photo or Name.",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Custom Earbuds, Chargers & Cables – Make It Yours | India</title>
        <meta
          name="description"
          content="Shop Make It Yours for custom earbuds, chargers, and cables. Serving Kerala, Karnataka, Tamil Nadu, Mumbai, Bengaluru, Jaipur, Delhi, Hyderabad, Ahmedabad, Gujarat."
        />
        <meta
          name="keywords"
          content="Custom earbuds with superior sound quality in Kerala and Karnataka | Buy personalized mobile chargers online in Mumbai and Bengaluru | High-quality custom cables for mobile devices in Jaipur and Delhi | Best custom earbuds with fast delivery to Ahmedabad and Gujarat | Stylish and durable custom mobile cables in Tamil Nadu and Kerala | Affordable custom fast mobile chargers in Mumbai and Jaipur | Top-rated custom earbuds and chargers for Hyderabad and Ahmedabad | Personalized mobile charging cables delivered to Delhi and Gujarat | Custom tech accessories including earbuds and chargers in Bengaluru and Mumbai"
        />
        <link rel="canonical" href="https://makeitur.com/" />
      </Helmet>
      <section>
        <Slider {...settings}>
          {sliderImages.map((src, index) => (
            <div key={index}>
              <img
                src={src}
              alt={`Slide ${index + 1}`}
                className="w-full md:h-[580px] h-60 img-fluid object-fit"
              />
            </div>
          ))}
        </Slider>
      </section>
      <section>
  <div className="container-fluid md:pl-16 md:pr-16 pl-3 pr-3 mx-auto md:py-2 py-4 bg-gray-100">
    <h1 className="md:text-3xl text-xl font-bold text-center mb-6 md:p-5">
      Best Customized Earbuds Collection
    </h1>
    <div className="grid md:grid-cols-4 md:gap-8 grid-cols-2 gap-4">
      {products.length > 0 ? (
        products.map((product, index) => {
          const isOutOfStock = (() => {
            switch (selectedSubcategory) {
              case "W90-CP":
                return [2, 3, 6, 7].includes(index);
              case "B93":
                return [2, 6, 10, 12].includes(index);
              case "b97":
                return [1, 2, 3, 5, 6].includes(index);
              
                default:
                  return false;
            }
          })();

          return (
            <div key={product.id || index}>
              <div className="relative bg-white shadow-md rounded-lg overflow-hidden">
                {/* Show "Customized" label regardless of stock status */}
                <span className={`flex gap-1 absolute top-1 right-0 bg-gray-200 text-black md:text-sm text-xs font-bold py-1 px-2 ${isOutOfStock ? "opacity-100" : ""}`}>
                  Customized
                  <FaPenAlt />
                </span>

                <div
                  className="cursor-pointer pt-7 md:pt-4"
                  onClick={() => handleAddToCart(product)}
                >
                  <img
                    src={product.photo}
                    alt={product.title}
                    className="h-32 sm:h-48 w-48 sm:w-60 mx-auto object-contain transform transition duration-300 hover:scale-95"
                  />
                </div>

                <div className="md:p-2 p-1">
                  <p className="text-xl line-clamp-2 text-center">
                    <span className="text-lg font-bold">
                      {product.productName}
                    </span>
                  </p>
                  <p className="text-sm text-gray-500 text-center">
                    {product.description ? (
                      <>
                        <span className="block sm:hidden">
                          {`${product.description.slice(0, 40)}...`}
                        </span>
                        <span className="hidden sm:block md:hidden">
                          {`${product.description.slice(0, 60)}...`}
                        </span>
                        <span className="hidden md:block">
                          {`${product.description.slice(0, 80)}...`}
                        </span>
                      </>
                    ) : (
                      "No description available."
                    )}
                  </p>
                  <div className="flex items-center justify-center space-x-1 text-center pt-1">
                    {Array.from({ length: 5 }, (_, index) => (
                      <span
                        key={index}
                        onClick={() =>
                          handleRatingClick(product._id, index + 1)
                        }
                        className={`cursor-pointer ${
                          selectedRatings[product._id] &&
                          index < selectedRatings[product._id]
                            ? "text-yellow-500"
                            : "text-gray-400"
                        }`}
                      >
                        <FaStar />
                      </span>
                    ))}
                  </div>
                  <p className="text-lg text-center pt-1">
                    <span className="text-sm text-gray-500 line-through ">
                      ₹{product.price}
                    </span>
                    <span className="text-base font-semibold ml-4">
                      ₹{product.discountPrice}
                    </span>
                  </p>
                  <div className="flex justify-center">
                    <button
                      className={`rounded border border-black ${
                        isOutOfStock
                          ? "bg-gray-300 text-gray-500" // Styling for out of stock
                          : "bg-black text-white hover:bg-white hover:text-black hover:border"
                      } w-full p-2 font-bold shadow-lg`}
                      onClick={() => {
                        if (isOutOfStock) {
                          alert("This product is currently out of stock.");
                        } else {
                          handleAddToCart(product);
                        }
                      }}
                    >
                      {/* Show "Out of Stock" label but keep button visible */}
                      {isOutOfStock ? "Out of Stock" : "Buy Now"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="col-span-full text-center text-gray-500">
          No products found in this category.
        </p>
      )}
    </div>
  </div>
</section>



      <section className="md:py-12 py-8 text-center font-varela md:px-24 px-4">
        <div className="container">
          <div className="bg-gray-300 from-primary to-primary/70 text-black rounded-3xl md:p-8 p-3 hover:scale-105 duration-500 hover:shadow-2xl">
            <div>
              <p className="font-bold md:text-4xl text- max-w-[700px] mx-auto leading-normal">
                Earbuds With Good Quality And Affordable Price
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="md:px-24 px-6">
        <div className="container  grid grid-cols-1 md:grid-cols-2 space-y-6 md:space-y-0 md:gap-12 gap-8 ">
          <div>
            <video
              src="./homevideo.mp4"
              autoPlay
              muted
              controls
              loop
              className="img-fluid mt-5"
              alt="homevideo"
              playsInline
              style={{ height: "100%", width: "100%", pointerEvents: "none" }}
            ></video>
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-center md:text-left ">
              <p
                className="md:text-3xl text-xl text-left font-semibold font-poppins md:mt-5 mt-0"
                style={{ opacity: "1", transform: "none", willChange: "auto" }}
              >
                The Latest Earbuds With The Latest Technology
              </p>
              <p
                className="mt-5 text-left"
                style={{ opacity: "1", transform: "none", willChange: "auto" }}
              >
                Experience like never before with our Customized Products, where
                cutting-edge technology meets personal expression. Designed for
                those who value individuality and top-tier performance, these
                products offer a fully personalized listening experience that
                caters to your unique preferences and style.
              </p>
              <div className="text-left">
                <a href="/earbuds">
                  {" "}
                  <button
                    className="border-2  mt-5 border-[#e33343] text-[#e33343] px-6 py-2 rounded-md hover:bg-[#e33343] hover:text-white"
                    style={{
                      opacity: "1",
                      willChange: "auto",
                    }}
                  >
                    Shop Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-4 mt-2">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-4 bg-gray-100">
          {services.map((service, index) => (
            <div key={index} className="p-4 flex flex-col items-center">
              <div className="p-2">
                <img
                  src={service.imgSrc}
                  className=" h-12 w-12"
                  alt={service.title}
                />
              </div>
              <div className="p-2 text-center">
                <p className="text-xl font-medium">{service.title}</p>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
