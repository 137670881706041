import React from "react";
import { Helmet } from "react-helmet";
const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us – Make It Yours | Custom Tech Accessories</title>
        <meta
          name="description"
          content="Need support or inquiries? Contact Make It Yours, serving Kerala, Karnataka, Tamil Nadu, Mumbai, Bengaluru, Jaipur, Delhi, Hyderabad, Ahmedabad, Gujarat. We're here to help!"
        />
        <meta
          name="keywords"
          content="Contact Make It Yours for custom tech accessories in Kerala and Karnataka | Get support for tech inquiries in Tamil Nadu and Mumbai | Reach out to Make It Yours for tech accessories in Bengaluru and Jaipur | Customer service for custom tech accessories in Delhi and Hyderabad | Contact Make It Yours for support in Ahmedabad and Gujarat | Inquiries about custom tech products in Kerala and Tamil Nadu | Support for tech accessories across Karnataka and Mumbai | Contact us for custom tech solutions in Bengaluru and Delhi | Get help with tech accessories from Make It Yours in Jaipur and Hyderabad | Custom tech support and inquiries in Ahmedabad and Gujarat"
        />
          <link rel="canonical" href="https://makeitur.com/contact" />
      </Helmet>
      <div className="relative  w-full ">
        <img
          className="md:h-96 h-64 w-full  object-fill"
          src="./contact.jpg"
          alt="contact"
        />
        <div className="absolute inset-0 bg-gray-700 opacity-60 "></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white md:text-6xl text-4xl font-bold">Contact Us</h1>
        </div>
      </div>
      <section className="relative bg-transparent">
        <div className="container grid grid-cols-1 md:grid-cols-3 gap-10 mx-auto md:my-20 my-10 max-w-screen-lg">
          <div className="flex flex-col items-center justify-center">
            <a
              href="mailto:makeityours1010@gmail.com?subject=Inquiry from Website"
              className="flex flex-col items-center justify-center"
            >
              <div className="w-12 h-12 mb-2 text-[var(--pc-site-color-df15a)]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  fill="currentColor"
                  className="w-full h-full"
                >
                  <path d="M44 8c.093 0 .186.003.278.01h.098a2 2 0 0 1 .77.155A4.006 4.006 0 0 1 48 12v24a4.002 4.002 0 0 1-2.855 3.834 1.956 1.956 0 0 1-.61.15l-.159.006h-.098A3.67 3.67 0 0 1 44 40H3.675c-.265 0-.519-.052-.75-.146A4.002 4.002 0 0 1 0 36V12a4.002 4.002 0 0 1 2.924-3.854c.174-.07.36-.117.554-.136L3.675 8H44ZM29.767 25.323l-3.139 2.722a3.994 3.994 0 0 1-2.317.97l-.207.01h-.208a4.004 4.004 0 0 1-1.226-.226l-.197-.076a4.004 4.004 0 0 1-.89-.511l-.205-.167-3.129-2.72-14.55 12.653c.05.007.1.013.152.017l.122.004 40.22-.008a2 2 0 0 0 .158-.022L29.767 25.323ZM2.135 11.277l-.044.125a1.992 1.992 0 0 0-.086.449L2 12v24c0 .255.048.5.135.724l.102-.115.125-.119L16.726 24 2.362 11.51a2 2 0 0 1-.227-.233Zm43.748.047-.078.085-.12.112L31.293 24l14.394 12.479c.07.061.137.127.197.196.06-.166.098-.342.112-.526L46 36V12c0-.237-.041-.465-.117-.676ZM3.998 10c-.1 0-.2.008-.3.023l18.993 16.513a2 2 0 0 0 2.342.205l.146-.097.134-.106L44.35 10.031a2 2 0 0 0-.156-.022L3.998 10Z" />
                </svg>
              </div>
              <h3 className="text-center text-[var(--pc-site-color-d181b)] text-2xl font-semibold">
                Send us message
              </h3>
            </a>
          </div>
 <div className="flex flex-col items-center justify-center">
            <a
              href="tel:+917600483333"
              className="flex flex-col items-center justify-center"
            >
              <div className="w-12 h-12 mb-2 flex items-center justify-center text-[var(--pc-site-color-df15a)]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  fill="currentColor"
                  className="w-full h-full"
                >
                  <g fill="currentColor">
                    <path d="M32 0a6 6 0 0 1 6 6v36a6 6 0 0 1-6 6H16a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6h16Zm0 2H16a4 4 0 0 0-3.995 3.8L12 6v36a4 4 0 0 0 3.8 3.995L16 46h16a4 4 0 0 0 3.995-3.8L36 42V6a4 4 0 0 0-3.8-3.995L32 2Z" />
                    <rect x="18" y="5" width="12" height="2" rx="1" />
                    <circle cx="24" cy="41" r="2" />
                  </g>
                </svg>
              </div>
              <h3 className="text-center text-[var(--pc-site-color-d181b)] text-2xl font-semibold">
                Give us a call
              </h3>
            </a>
          </div>
<div className="flex flex-col items-center justify-center">
            <a
              href="https://maps.app.goo.gl/LrFzYFihBe2SoJX77"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center justify-center"
            >
              <div className="w-12 h-12 mb-2 text-[var(--pc-site-color-df15a)]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  fill="currentColor"
                  className="w-full h-full"
                >
                  <g fill="currentColor">
                    <circle cx="24" cy="16" r="4" />
                    <path d="M24 0c8.837 0 16 7.163 16 16 0 5.661-4.925 15.733-14.776 30.215l-.397.582a1 1 0 0 1-1.572.104l-.077-.1-.788-1.158C12.796 31.468 8 21.587 8 16 8 7.163 15.163 0 24 0Zm0 2c-7.732 0-14 6.268-14 14 0 4.912 4.431 14.165 13.296 27.408L24 44.455l.323-.478c8.995-13.365 13.551-22.737 13.674-27.77L38 16c0-7.732-6.268-14-14-14Z" />
                  </g>
                </svg>
              </div>
              <h3 className="text-center text-[var(--pc-site-color-d181b)] text-2xl font-semibold">
                Come for a visit
              </h3>
            </a>
          </div>
        </div>
      </section>
      <section className="bg-blue-50 dark:bg-slate-800" id="contact">
        <div className="mx-auto max-w-7xl px-4  sm:px-6 lg:px-8 lg:py-1">
          <div className="flex items-stretch justify-center">
            <div className="grid md:grid-cols-2">
              <div className="h-full pr-6">
                <div className="mb-6 mt-14 max-w-3xl text-left sm:text-center md:mx-auto md:mb-12">
                  <p className="font-heading text-left mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
                    Get in touch with us
                  </p>
                  <p className="mx-auto mt-4 text-left max-w-3xl text-xl text-gray-600 dark:text-slate-400">
                    Elevate your tech experience with our customized earbuds,
                    chargers, and cables. Enjoy personalized style, enhanced
                    functionality, and superior quality tailored to fit your
                    unique needs.
                  </p>
                </div>
              </div>
              <div className="card h-fit max-w-6xl  md:p-12" id="form">
                <form
                  id="contactForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const name = e.target.name.value;
                    const email = e.target.email.value;
                    const message = e.target.textarea.value;
const whatsappMessage = `Hello, my name is ${name}. My email is ${email}. Message: ${message}`;
const phoneNumber = "+917600483333";
                    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                      whatsappMessage
                    )}`;
window.open(whatsappUrl, "_blank");
                  }}
                >
                  <div className="mb-6">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          htmlFor="name"
                          className="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <input
                          type="text"
                          id="name"
                          autoComplete="given-name"
                          placeholder="Your name"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                          name="name"
                          required
                        />
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          htmlFor="email"
                          className="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <input
                          type="email"
                          id="email"
                          autoComplete="email"
                          placeholder="Your email address"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                          name="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label
                        htmlFor="textarea"
                        className="pb-1 text-xs uppercase tracking-wider"
                      ></label>
                      <textarea
                        id="textarea"
                        name="textarea"
                        cols="30"
                        rows="5"
                        placeholder="Write your message..."
                        className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="w-full bg-black text-white px-6 py-4 mb-5 font-xl rounded-md sm:mb-0"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="relative w-full h-96">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.2821057518945!2d72.52868287960413!3d23.050116892173147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8579bd545161%3A0xc9f79b3707047b16!2sMedia%20Tech%20Solutions%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1722841476828!5m2!1sen!2sin"
          width="600"
          height="450"
       aria-hidden="false"
          loading="lazy"
          title="sd"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
