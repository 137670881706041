import React from "react";

const TermsCondition = () => {
  return (
    <div>
      <p className="text-4xl font-semibold text-center mt-5">Terms of Use</p>
      <div className="p-8">
        <ol>
          <li className="mt-5 text-lg">1. USE OF THE PLATFORM</li>
        </ol>
<p className="mt-5 text-base">
          Welcome to{" "}
          <a
            href="http://www.makeityours.com"
            data-mce-fragment="1"
            data-mce-href="http://www.makeityours.com"
          >
          <span className='text-red-600'>make it yours</span>
          </a>{" "}
             The website
          www.makeityours.com is owned and operated by Imagine Marketing Private
          Limited ("<strong data-mce-fragment="1">Company</strong>” or “
          <strong data-mce-fragment="1">We</strong>” or “
          <strong data-mce-fragment="1">Our</strong>”) a company incorporated
          under the provisions of the Companies Act, 1956. 
        </p>
 <p className="mt-5 text-base">
          The Site is only to be used for your personal non-commercial use and
          information. Your use of the Site and features of the Site will be
          governed by these Terms and Conditions (hereinafter "&nbsp;
          <strong data-mce-fragment="1">Terms of Use&nbsp;</strong>") along with
          the Privacy Policy, Shipping Policy and Cancellation, Refund and
          Return Policy (together "&nbsp;
          <strong data-mce-fragment="1">Policies&nbsp;</strong>") as modified
          and amended from time to time.
        </p>
<ol>
          <li className="mt-5 text-lg">2. PRIVACY PRACTICES</li>
        </ol>
<p className="mt-5 text-base">
          We understand the importance of safeguarding your personal information
          and we have formulated a Privacy Policy, to ensure that your personal
          information is sufficiently protected. Apart from these Terms of Use,
          the Privacy Policy will also govern your visit and use of the Site.
          Your continued use of the Site implies that you have read and accepted
          the Privacy Policy and agree to be bound by its terms and conditions.
          You consent to the use of personal information by the Company in
          accordance with the terms of and purposes set forth in the Privacy
          Policy, the same may be subject to amendment from time to time at the
          sole discretion of the Company.&nbsp;
        </p>
         <ol>
          <li className="mt-5 text-lg">3. PRODUCT INFORMATION</li>
        </ol>
<p className="mt-5 text-base">
          Company attempts to be as accurate as possible in the description of
          the product on the Site. However, Company does not warrant that the
          product description, colour, information or other content of the Site
          is accurate, complete, reliable, current or error-free. The Site may
          contain typographical errors or inaccuracies and may not be complete
          or current. The product pictures are indicative and may not match the
          actual product. In the event of any such inadvertent inaccuracies, the
          Company will endeavour to correct the same within the earliest
          possible time period.
        </p>
<p className="mt-5 text-base">
          Company reserves the right to correct, change or update information,
          errors, inaccuracies or omissions at any time (including after an
          order has been submitted ) without prior notice to ensure accuracy of
          descriptions of the product on the site.
        </p>
 <ol>
          <li className="mt-5 text-lg">4. PRODUCT USE</li>
        </ol>
 <p className="mt-5 text-base">
          The products and services available on the Site, and the samples, if
          any, that Site may provide you, are for your personal and/or
          professional use only. The products or services, or samples thereof,
          which you may receive from us, will not be sold or resold for any
          reasons, commercial or otherwise.
        </p>
<ol>
          <li className="mt-5 text-lg">
            5. CANCELLATIONS, REFUNDS AND RETURNS
          </li>
        </ol>
<p className="mt-5 text-base">
          Please refer to our&nbsp;Warranty and Support page provided on our
          Site.
        </p>
 <ol>
          <li className="mt-5 text-lg">6. MODE OF PAYMENT</li>
        </ol>
<p className="mt-5 text-base">
          Payments for the products available on the Site may be made in the
          following ways:
        </p>
        <ul>
          <li className="mt-5 text-base">
            Payments can be made by Credit Cards, Debit Cards, Net Banking,
            Wallets, e-Gift cards, and reward points.
          </li>
          <li className="mt-5 text-base">
            Credit card, Debit Card and Net Banking payment options are instant
            payment options and recommended to ensure faster processing of your
            order.
          </li>
        </ul>
<ol>
          <li className="mt-5 text-lg">7. INTELLECTUAL PROPERTY RIGHTS</li>
        </ol>
<p className="mt-5 text-base">
          The names and logos and all related product names, design marks and
          slogans are the trademarks, logos or service marks (hereinafter
          referred to as&nbsp;<strong data-mce-fragment="1">"Marks"</strong>) of
          the Company. No trademark or service mark license is granted in
          connection with the materials contained on this Site. Access to this
          Site does not authorize anyone to use any Marks in any manner. Marks
          displayed on this Site, whether registered or unregistered, of the
          Company are the intellectual property of the Company.
        </p>
 <p className="mt-5 text-base">
          The Company expressly and exclusively reserve all the intellectual
          property rights in all text, programs, products, processes,
          technology, content, software and other materials, which appear on
          this Site, including its looks and feel. The compilation (meaning the
          collection, arrangement and assembly) of the content on the Site is
          the exclusive property of the Company and are protected by the Indian
          copyright laws and International treaties. Consequently, the materials
          on this Site will not be copied, reproduced, duplicated, republished,
          downloaded, posted, transmitted, distributed or modified in whole or
          in part or in any other form whatsoever, except for your personal,
          non-commercial use only. No right, title or interest in any downloaded
          materials or software is transferred to you as a result of any such
          downloading or copying, reproducing, duplicating, republishing,
          posting, transmitting, distributing or modifying.
        </p>
<ol>
          <li className="mt-5 text-lg">8. LIMITED LICENSE</li>
        </ol>
<p className="mt-5 text-base">
          The Company grants you limited, non-exclusive, non-transferable,
          non-sublicensable license to access, and make personal and
          non-commercial use of the Site. All rights not expressly granted to
          you in these Terms of Use, are reserved and retained by the Company
          and its affiliates.
        </p>
 <p className="mt-5 text-base">
          The Company reserves the right, at any time, without notice, and at
          its sole discretion, to terminate your license to use the Site and to
          block and prevent your future access the Site.
        </p>
<p className="mt-5 text-base">
          The Company is entitled to investigate occurrences that may involve
          such violations and may involve, and co-operate with, law enforcement
          authorities in prosecuting users who are involved in such violations.
          You agree not to use any device, software or routine to interfere or
          attempt to interfere with the proper working of this Site or any
          activity being conducted on this Site. You agree, further, not to use
          or attempt to use any engine, software, tool, agent or other device or
          mechanism (including without limitation browsers, spiders, robots,
          avatars or intelligent agents) to navigate or search this Site other
          than the search engine and search agents available from the Company on
          this Site and other than generally available third party web browsers
          (e.g., Netscape Navigator, Microsoft Explorer).&nbsp;
        </p>
<p className="mt-5 text-base">
          We will report cyber security incidents with relevant statutory
          authorities and will render all reasonable cooperation as required by
          statutory authorities with respect to a cyber security incident. In
          accordance with applicable laws, we may be required to provide
          information to law enforcement agencies within 72 hours to verify the
          identity of any user for the purposes of prosecution or investigation
          of cyber security offences.
        </p>
<p className="mt-5 text-base">
          Data collected from you during your use of the Site will be retained
          for at least a period of 180 days, in compliance with applicable laws.
        </p>
<ol>
          <li className="mt-5 text-lg">9. CONTENT AND USER SUBMISSIONS</li>
        </ol>
<p className="mt-5 text-base">
          For purposes of these Terms of Use, the term “Content” includes,
          without limitation, any location information, logos, images, videos,
          audio clips, comments, information, data, text, photographs, software,
          scripts, and graphics made available by you on the Site. Any such
          Content added, created, uploaded, submitted, posted during usage of
          the Site by You is collectively referred to as, “User Submissions.”
        </p>
<p className="mt-5 text-base">
          All Content and User Submissions, whether publicly posted or privately
          transmitted, will be the sole responsibility of the person who
          originated such user Submissions/Content. We cannot guarantee the
          authenticity of any such User Submissions, and/or Content which the
          users may provide about themselves or about the products sold on the
          Site. You acknowledge and agree that (i) all User Submissions/Content
          accessed by you, while utilizing or using the Site will be at your own
          risk and will be solely responsible and liable for any damage or loss
          that may occur as may be attributable to you or any other party
          resulting therefrom; (ii) we will have no liability towards you or any
          such party for any damage or loss resulting from such User
          Submissions/Content; and (iii) You will be responsible and liable for
          displaying of authentic Content/User Submissions while using the Site
          and we will have no liability in this regard.
        </p>
<p className="mt-5 text-base">
          You grant us a worldwide, exclusive and royalty free license to use
          and reproduce the User Submissions/ Content at our discretion.
        </p>
<p className="mt-5 text-base">
          With respect to the Content and User Submissions, you are prohibited
          from displaying, uploading, modifying, publishing, transmitting,
          updating or sharing on or through the Site, any information that:
      </p>
<ol>
          <li className="mt-5 text-base">
            1. belongs to another person and to which You do not have any right;
          </li>
          <li className="mt-3 text-base">
            2. is harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, invasive of another’s privacy, hateful,
            relating to or encouraging money laundering or gambling, or
            otherwise harmful in any manner whatsoever;
          </li>
          <li className="mt-3 text-base">3. harms minors in any way;</li>
          <li className="mt-3 text-base">
            4. infringes any patent, trademark, copyright or other proprietary
            rights;
          </li>
          <li className="mt-3 text-base">
            5. violates any law for the time being in force;
          </li>
          <li className="mt-3 text-base">
            6. deceives or misleads the addressee about the origin of such
            messages or communicates any information which is grossly offensive
            or menacing in nature;
          </li>
          <li className="mt-3 text-base">
            7. impersonates or defames another person;
          </li>
          <li className="mt-3 text-base">
            8. contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; or
          </li>
          <li className="mt-3 text-base">
            9. threatens the unity, integrity, defense, security or sovereignty
            of India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation.
          </li>
        </ol>
<ol>
          <li className="mt-5 text-lg">10. TERMINATION</li>
        </ol>
<p className="mt-5 text-base">
          These Terms of Use are effective unless and until terminated by either
          you or the Company.
        </p>
        <p className="mt-5 text-base">
          You may terminate the Terms of Use at any time, provided that you
          discontinue any further use of this Site. The Company may terminate
          the Terms of Use at any time and may do so without notice, and
          accordingly deny you access to the Site, such termination will be
          without any liability to the Site. Termination of the Terms of Use
          will also lead to the termination of the Policies.
        </p>
 <p className="mt-5 text-base">
          Upon any termination of the Terms of Use by either you or the Company,
          you must promptly destroy all materials downloaded or otherwise
          obtained from this Site, as well as all copies of such materials,
          whether made under the Terms of Use or otherwise. Any such termination
          of the Terms of Use will not cancel your obligation to pay for the
          product already ordered from the Site or affect any liability that may
          have arisen under the Terms of Use.
        </p>
<ol>
          <li className="mt-5 text-lg">11. GOVERNING LAW AND JURISDICTION</li>
        </ol>
<p className="mt-5 text-base">
          The Terms of Use and the Policies will be construed in accordance with
          the applicable laws of India. For proceedings arising therein, the
          Courts at Mumbai will have exclusive jurisdiction, subject to the
          arbitration process below.
        </p>
<ol>
          <li className="mt-5 text-lg">12. ENTIRE AGREEMENT</li>
        </ol>
        <p className="mt-5 text-base">
          Unless otherwise specified herein, these Terms of Use and the Policies
          constitutes the entire agreement between you and Company with respect
          to the Site and it supersedes all prior or contemporaneous
          communications and proposals, whether electronic, oral or written.
        </p>
        <p className="mt-5 text-base">
          Company's failure to act with respect to a breach by you or others
          does not waive its right to act with respect to the breach or
          subsequent and similar breaches.
        </p>
<p className="mt-5 text-base">
          <strong data-mce-fragment="1">
            **All Partnership/Affiliates coupon codes will be invalid during the
            sales period. However, they will be redeemable and functional as the
            sale ends.
          </strong>
        </p>
      </div>
    </div>
  );
};

export default TermsCondition;