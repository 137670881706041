import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import Home from "./Screen/Home";
import Contact from "./Screen/Contact";
import Cables from "./Screen/Cables";

import Cart from "./Screen/Cart";
import CheckOut from "./Screen/CheckOut";
import Earbuds from "./Screen/Earbuds";
import Charger from "./Screen/Charger";
import Register from "./Common/Register";
import Login from "./Common/Login";
import ResetPassword from "./Screen/ResetPassword";
import AboutUs from "./Screen/AboutUs";
import TermsCondition from "./Screen/TermsCondition";
import PrivacyPolicy from "./Screen/PrivacyPolicy";
import ReturnPolicy from "./Screen/ReturnPolicy";
import PageNotFound from "./Screen/PageNotFound";
import ProductDetails from "./Screen/ProductDetails";



const Navbar = () => {
  const [totalItems, setTotalItems] = useState(0);
  const updateTotalItems = (cartItems) => {
    const count = cartItems.reduce((acc, item) => acc + item.quantity, 0);
    setTotalItems(count);
    localStorage.setItem("totalItems", count); 
  };
 useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    updateTotalItems(cart);
  }, []);
return (
    <>
      <div className="App ">
        <BrowserRouter>
         
          <Routes>
            <Route path="/"  element={
                <>
                  <Header totalItems={totalItems} />
                  <Home />
                  <Footer />
                </>
              } />
            <Route path="/about"  element={
                <>
                  <Header totalItems={totalItems} />
                  <AboutUs />
                  <Footer />
                </>
              } />
            <Route path="/contact"  element={
                <>
                  <Header totalItems={totalItems} />
                  <Contact />
                  <Footer />
                </>
              } />
            <Route path="/cable"  element={
                <>
                  <Header totalItems={totalItems} />
                  <Cables />
                  <Footer />
                </>
              }/>
            <Route path="/earbuds"  element={
                <>
                  <Header totalItems={totalItems} />
                  <Earbuds />
                  <Footer />
                </>
              } />
            <Route path="/charger" element={
                <>
                  <Header totalItems={totalItems} />
                  <Charger />
                  <Footer />
                </>
              } />
            <Route path="/product/gaming-earbuds-j171-3d/:id"  element={
                <>
                  <Header totalItems={totalItems} />
                  <ProductDetails />
                  <Footer />
                </>
              }/>
                <Route path="/product/cable/:id"  element={
                <>
                  <Header totalItems={totalItems} />
                  <ProductDetails />
                  <Footer />
                </>
              }/>
              <Route path="/product/charger/:id"  element={
                <>
                  <Header totalItems={totalItems} />
                  <ProductDetails />
                  <Footer />
                </>
              }/>
            <Route path="/register"  element={
                <>
                  <Header totalItems={totalItems} />
                  <Register />
                  <Footer />
                </>
              } />
            <Route path="/login" element={
                <>
                  <Header totalItems={totalItems} />
                  <Login />
                  <Footer />
                </>
              } />
            <Route path="/terms-and-condition"  element={
                <>
                  <Header totalItems={totalItems} />
                  <TermsCondition />
                  <Footer />
                </>
              }/>
            <Route path="/privacy-policy"  element={
                <>
                  <Header totalItems={totalItems} />
                  <PrivacyPolicy />
                  <Footer />
                </>
              }/>
            <Route path="/return-policy"   element={
                <>
                  <Header totalItems={totalItems} />
                  <ReturnPolicy />
                  <Footer />
                </>
              }/>
           <Route
              path="/cart"
              element={
                <>
                  <Header totalItems={totalItems} />
                  <Cart onUpdateTotalItems={updateTotalItems} />
                  <Footer />
                </>
              }
            />
            <Route path="/checkout"   element={
                <>
                  <Header totalItems={totalItems} />
                  <CheckOut />
                  <Footer />
                </>
              } />
            <Route path="/reset-password"   element={
                <>
                  <Header totalItems={totalItems} />
                  <ResetPassword />
                  <Footer />
                </>
              } />
                 
              
           <Route path="*" element={<PageNotFound/>}/>
          </Routes>
        </BrowserRouter>
       
      </div>
    </>
  );
};

export default Navbar;
