import React from "react";
import WalletIcon from '@mui/icons-material/Wallet';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentsIcon from '@mui/icons-material/Payments';
const Footer = () => {
  return (
    <div>
     
      <footer className="bg-black">
        <div className="mx-auto w-full p-4 lg:py-8">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 p-4 item-center">
            <div className="mb-6 md:mb-0">
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                <img
                  src="/logo1.png"
                  alt="/"
                  className="w-64"
                  width="64%"
                  // height="96"
                />
              </span>

              <p className="text-white">
                "Welcome to <b>Make it yours brand</b>, where sound meets style
                in perfect harmony! Elevate your listening experience with our
                custom-designed earbuds, crafted to suit your unique tastes and
                preferences.
              </p>
            </div>

            <div className="md:pl-12 pl-0">
              <p className="mb-6 text-xl font-semibold text-white uppercase">
                Navigation
              </p>
              <ul className="flex gap-16 text-white font-medium text-sm">
                <div>
                  <li className="mb-4">
                    <a href="/" className="hover:underline">
                      Home
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="/about" className="hover:underline">
                      About
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="/cable" className="hover:underline">
                      Cable
                    </a>
                  </li>
                </div>
                <div>
                  <li className="mb-4">
                    <a href="/charger" className="hover:underline">
                      Charger
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="/earbuds" className="hover:underline">
                      Earbuds
                    </a>
                  </li>
                  <li>
                    <a href="/contact" className="hover:underline">
                      Contact
                    </a>
                  </li>
                </div>
              </ul>
            </div>
            <div className="md:pl-12 pl-0">
              <p className="mb-6 text-xl font-semibold text-white uppercase">
                Information
              </p>
              <ul className="flex gap-16 text-white font-medium text-sm">
                <div>
                  <li className="mb-4">
                    <a href="/terms-and-condition" className="hover:underline">
                      Terms & Condition
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="/privacy-policy" className="hover:underline">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="/return-policy" className="hover:underline">
                      Return Policy
                    </a>
                  </li>
                </div>
              </ul>
            </div>

            <div className="md:pl-10 pl-0">
              <p className="mb-6 text-xl font-semibold uppercase text-white">
                Follow Us
              </p>
              <ul className="flex gap-4 text-white text-2xl">
                <li>
                  <a
                    href="https://www.instagram.com/makeityours1010/"
                    className="bg-white text-black p-1 rounded"
                    target="/"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61567169340324"
                    className="bg-white text-black p-1 rounded"
                    target="/"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <p className="mb-6 text-xl font-semibold text-white uppercase">
                Contact
              </p>
              <ul className="text-white font-medium text-sm">
                <li className="mb-2 flex items-center">
                  <a
                    href="tel:+917600483333"
                    style={{ textDecoration: "none" }}
                    target="/"
                  >
                    <i className="fa-solid fa-phone mr-3 text-lg"></i>+91 76004
                    83333
                  </a>
                </li>
                <li className="mb-2 flex items-center">
                  <a
                    href="mailto:makeityours1010@gmail.com"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa-regular fa-envelope mr-3 text-lg"></i>
                    makeityours1010@gmail.com
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    href="https://maps.app.goo.gl/B4MCC6fydyFady2Q8"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa-solid fa-location-dot mr-3 text-lg"></i>
                    502, 5th Floor, Pushti Heights, Gurukul Rd, Nilmani Society,
                    Memnagar, Ahmedabad, Gujarat 380052
                  </a>
                </li>
              </ul>
            </div>
          </div>
          
          
<hr className="my-6 border-gray-400" />
          <div className="md:flex justify-between md:px-20">
           <div className="flex flex-wrap">
           <p className="text-white text-sm md:pr-4 pr-2 pt-1">We accept</p>
           <img src="/CardPayment.png" className="md:w-72 w-48" alt="/"/>
           <ul className="text-white text-xs md:text-sm flex md:pl-5 md:pt-1 pt-3 gap-1  ">
         <AccountBalanceWalletIcon className="pl-1 "/> <li className="pt-1">Net Banking</li>
          <WalletIcon className="pl-1" /><li className="pt-1">Wallets</li>
          <PaymentsIcon className="pl-1"/>  <li className="pt-1">Cash On Delivery</li>
           </ul>
           </div>
           <div className="text-white">
          <div className="flex justify-center m-4 md:m-0">
          <p className="text-white text-sm pt-1">Built With</p>
         <a href="https://www.mannix.in/"> <img src="/BuiltLogo.png" alt="BuiltLogo" className="w-32"/></a>
          </div>
           </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
