import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { FiPlus, FiMinus } from "react-icons/fi";
import Slider from "react-slick";

const relatedProducts = {
  "Earbuds b97": {
    images: ["/1.png", "/2.png", "/3.png"],
    description: (
      <>
        <p className="md:mt-7 mt-2 ">
          Explore our range of stylish and high-quality earbuds designed for
          immersive sound and comfort. Whether you're commuting, working out, or
          just relaxing at home, our earbuds provide a superior audio experience
          that allows you to enjoy your favorite music, podcasts, and calls with
          crystal-clear clarity. With a variety of styles and colors to choose
          from, you're sure to find the perfect pair that suits your personality
          and lifestyle.
        </p>
        <p className="md:mt-5 mt-2 ">
          Our earbuds are not just about sound quality; they also offer a
          comfortable fit for extended wear. Equipped with advanced features
          such as noise cancellation and sweat resistance, they are perfect for
          any activity. Plus, the compact design makes them easy to carry,
          ensuring you never miss a beat no matter where you go.
        </p>
      </>
    ),
  },
  "Earbuds B93": {
    images: ["/4.png", "/5.png", "/6.png"],
    description: (
      <>
        <p className="md:mt-7 mt-2 ">
          Explore our range of stylish and high-quality earbuds designed for
          immersive sound and comfort. Whether you're commuting, working out, or
          just relaxing at home, our earbuds provide a superior audio experience
          that allows you to enjoy your favorite music, podcasts, and calls with
          crystal-clear clarity. With a variety of styles and colors to choose
          from, you're sure to find the perfect pair that suits your personality
          and lifestyle.
        </p>
        <p className="md:mt-5 mt-2 ">
          Our earbuds are not just about sound quality; they also offer a
          comfortable fit for extended wear. Equipped with advanced features
          such as noise cancellation and sweat resistance, they are perfect for
          any activity. Plus, the compact design makes them easy to carry,
          ensuring you never miss a beat no matter where you go.
        </p>
      </>
    ),
  },
  "Earbuds W901": {
    images: ["/7.png", "/8.png", "/9.png"],
    description: (
      <>
        <p className="md:mt-7 mt-2 ">
          Explore our range of stylish and high-quality earbuds designed for
          immersive sound and comfort. Whether you're commuting, working out, or
          just relaxing at home, our earbuds provide a superior audio experience
          that allows you to enjoy your favorite music, podcasts, and calls with
          crystal-clear clarity. With a variety of styles and colors to choose
          from, you're sure to find the perfect pair that suits your personality
          and lifestyle.
        </p>
        <p className="md:mt-5 mt-2 ">
          Our earbuds are not just about sound quality; they also offer a
          comfortable fit for extended wear. Equipped with advanced features
          such as noise cancellation and sweat resistance, they are perfect for
          any activity. Plus, the compact design makes them easy to carry,
          ensuring you never miss a beat no matter where you go.
        </p>
      </>
    ),
  },
  "Earbuds J171-3D": {
    images: ["/1.png", "/2.png", "/3.png"],
    description: (
      <>
        <p className="md:mt-7 mt-2 ">
          Explore our range of stylish and high-quality earbuds designed for
          immersive sound and comfort. Whether you're commuting, working out, or
          just relaxing at home, our earbuds provide a superior audio experience
          that allows you to enjoy your favorite music, podcasts, and calls with
          crystal-clear clarity. With a variety of styles and colors to choose
          from, you're sure to find the perfect pair that suits your personality
          and lifestyle.
        </p>
        <p className="md:mt-5 mt-2 ">
          Our earbuds are not just about sound quality; they also offer a
          comfortable fit for extended wear. Equipped with advanced features
          such as noise cancellation and sweat resistance, they are perfect for
          any activity. Plus, the compact design makes them easy to carry,
          ensuring you never miss a beat no matter where you go.
        </p>
      </>
    ),
  },
  Charger: {
    images: [
      "/13.png",
      "/14.png",
      "/15.png",
    ],
    description: (
      <>
        <p className="md:mt-7 mt-2 ">
        Power up with our reliable, high-performance chargers designed to keep your devices ready whenever you are. Built for speed and efficiency, these chargers ensure rapid charging without compromising battery health. Compact and durable, they fit seamlessly into any lifestyle, whether at home, work, or on the go. With safety features like overcharge protection, you can count on these chargers to deliver consistent power safely to all your devices.
        </p>
       
      </>
    ),
  },
  Cable: {
    images: ["/10.png", "/11.png", "/12.png"],
    description: (
      <>
        <p className="md:mt-7 mt-2 ">
        Our versatile cables combine durability with superior connectivity for all your charging and data transfer needs. Crafted to withstand daily wear and tear, they provide a stable and fast connection, making them ideal for both charging and syncing across a variety of devices. Available in different lengths and types to suit your specific requirements, these cables are built for lasting performance and reliability wherever you go.
        </p>
       
      </>
    ),
  },
};
const colorOptions = {
  Earbuds: [
    { value: "black", label: "black" },
    { value: "white", label: "White" },
  ],
  Charger: [
    { value: "black", label: "Black" },
    { value: "white", label: "White" },
  ],
  Cable: [
    { value: "black", label: "Black" },
    { value: "white", label: "White" },
  ],
};
const images = [
  { src: "/image1.png", alt: "Related product 1" },
  { src: "/image2.png", alt: "Related product 2" },
  { src: "/image3.png", alt: "Related product 3" },
  { src: "/image4.png", alt: "Related product 4" },
];

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="absolute md:-left-2 left-9 top-1/2 transform -translate-y-1/2 bg-transparent border-none cursor-pointer text-black z-10" // Adjusted styles
      style={{ fontSize: "24px" }}
    >
      <div className="border border-black rounded-full bg-white md:p-3 p-2">
        <FaAngleLeft />
      </div>{" "}
    </button>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="absolute md:-right-1 right-9 top-1/2 transform -translate-y-1/2 bg-transparent border-none cursor-pointer text-black z-10" // Adjusted styles
      style={{ fontSize: "24px" }}
    >
      <div className="border border-black rounded-full bg-white md:p-3 p-2">
        <FaAngleRight />
      </div>{" "}
    </button>
  );
};

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);

  const [quantity, setQuantity] = useState(1);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [isProductAdded, setIsProductAdded] = useState(false);
  const [selectedOption, setSelectedOption] = useState("black");

  const [logoFile, setLogoFile] = useState(null);
  const [logoName, setLogoName] = useState("");

  const notificationRef = useRef(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://customizedapi.onrender.com/product/get/${id}`
        );
        setProduct(response.data.data);

        const productPrice = response.data.data.discountPrice;
        if (productPrice) {
          setSubtotal(productPrice * quantity);
          setTotal(productPrice * quantity);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id, quantity]);

  const { images = [], description } =
    product && product.subCategoryName
      ? relatedProducts[product.subCategoryName] || {}
      : {};

  const handleIncrement = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      updateSubtotal(newQuantity);
      return newQuantity;
    });
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
        updateSubtotal(newQuantity);
        return newQuantity;
      });
    }
  };

  useEffect(() => {
    if (product && product.discountPrice) {
      const updatedSubtotal = product.discountPrice * quantity;
      setSubtotal(updatedSubtotal);
      setTotal(updatedSubtotal);
      setSelectedColor(colorOptions[product.category]?.[0]?.value || "");
    }
  }, [product, quantity]);

  const updateSubtotal = (quantity) => {
    if (product && product.discountPrice) {
      const newSubtotal = product.discountPrice * quantity;
      setSubtotal(newSubtotal);
      setTotal(newSubtotal);
    }
  };
  const handleAddToCart = () => {
    if (!selectedColor) {
      toast.error("Please select a color before adding to the cart.");
      return;
    }

    try {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const newItem = {
        id: product._id,
        productName: product.productName,
        price: product.discountPrice,
        photo: product.photo,
        color: selectedColor,

        logoPhoto: logoFile,
        logoName: logoName,
        subCategoryName: product.subCategoryName,
        quantity,
      };

      const existingItemIndex = cart.findIndex(
        (item) => item.id === newItem.id
      );
      if (existingItemIndex > -1) {
        cart[existingItemIndex].quantity += quantity;
      } else {
        cart.push(newItem);
      }
      localStorage.setItem("cart", JSON.stringify(cart));
      setIsProductAdded(true);
      if (notificationRef.current) {
        notificationRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Error accessing localStorage:", error);
      alert(
        "There was an error adding the item to the cart. Please try again."
      );
    }
    navigate("/cart");
    window.scroll(0, 0);
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "customized_order");
      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/dfarrvnyi/image/upload",
          formData
        );
        console.log("Cloudinary Response:", response.data);
        if (response.data && response.data.secure_url) {
          setLogoFile(response.data.secure_url);
        } else {
          console.error("Secure URL not found in the response");
        }
      } catch (error) {
        console.error("Error uploading logo:", error);
      }
    } else {
      console.warn("No file selected for upload.");
    }
  };

  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://customizedapi.onrender.com/product/get/${id}`
        );
        setProduct(response.data.data);

        setMainImage(response.data.data.photo);

        const productPrice = response.data.data.discountPrice;
        if (productPrice) {
          setSubtotal(productPrice * quantity);
          setTotal(productPrice * quantity);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id, quantity]);

  const allImages = [mainImage, ...images].filter(Boolean);
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    initialSlide: currentSlide,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (current) => setCurrentSlide(current),
  };

  const handleRelatedImageClick = (image) => {
    setMainImage(image);
    const newIndex = allImages.indexOf(image);
    setCurrentSlide(newIndex);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const handleImageClick = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index);
  };
  const handleAfterChange = (currentIndex) => {
    setActiveIndex(currentIndex);
  };
  return (
    <div>
      <div className="container mx-auto md:p-8">
        <div>
          <h1 className="text-2xl font-semibold mb-4 md:mt-5 mt-3  md:px-0 px-5 pb-3">
            Shopping Cart
          </h1>
          <div className="flex flex-col md:flex-row gap-4 ">
            <div className=" w-full">
              <div className="relative inline-block">
                <p className="md:text-base text-md font-semibold bg-gray-300 md:p-3 p-2 md:m-0 mx-5 mt-1 ">
                  Customized Earbuds - Mark with NAME & PHOTO on Packaging
                </p>

                <span className="absolute -top-3 md:left-0 left-4 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
                  New Feature
                </span>
              </div>
              <div className="md:p-6 md:px-6 px-3 pt-6 mb-4">
                {product ? (
                  <div className="flex flex-col items-start w-full">
                    <div className="flex-shrink-0 md:w-[40vw] w-[90vw] mb-4 relative">
                      <Slider
                        ref={sliderRef}
                        {...sliderSettings}
                        initialSlide={activeIndex}
                        afterChange={handleAfterChange}
                      >
                        {allImages.map((image, index) => (
                          <div key={index}>
                            <img
                              loading="lazy"
                              className="h-64 w-64 md:h-[70vh] md:w-full p-2 md:p-5 border-dashed mx-auto"
                              src={image}
                              alt={`Product ${index + 1}`}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>

                    <div className="flex flex-col items-center w-full md:w-96">
                      <div className="flex md:gap-4 gap-1 md:px-6">
                        {allImages.map((image, index) => (
                          <img
                            key={index}
                            loading="lazy"
                            className={`h-20 w-20 md:h-28 md:w-28 border p-2 border-dashed cursor-pointer ${
                              index === activeIndex ? "border-black" : ""
                            }`}
                            src={image}
                            alt={`Main product ${index + 1}`}
                            onClick={() => handleImageClick(index)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-4">No products in cart.</div>
                )}
              </div>

              <div className="md:mt-8 mt-6 m-5 hidden md:block">
                <p className="font-semibold">About this Product:-</p>
                <ul className="p-2">
                  <li>• Lively Sound with Upbeat Bass</li>
                  <li>• Rich and Premium Angular earbuds</li>
                  <li>• Intuitive Touch Controls for Calls,Track & Volume </li>
                  <li>• Description of the fourth feature.</li>
                  <li>• Sweet Resistant - IPX4 rating</li>
                  <li>• Next Gen Type-C port for last charging</li>
                  <li>• Dual Support: Single Earbud & Double as TWS</li>
                  <li>• Voice Assistant (on default OS)</li>
                </ul>
              </div>
            </div>
            <div className="w-full">
              <div className="md:p-6 md:px-6  md:mb-6 mb-0 ">
                {product ? (
                  <div className="flex flex-col md:flex-row items-start gap-4 md:gap-6">
                    <div className="flex-1 space-y-2   md:p-0 p-5  ">
                      <div className="flex flex-col md:flex-row items-start justify-between text-left">
                        <h2 className="md:text-2xl text-2xl font-semibold capitalize">
                          {product.subCategoryName}
                        </h2>
                      </div>

                      <p className="md:text-base text-md pt-4 font-semibold">
                        {product.description}
                      </p>
                      <div className="flex items-center justify-between pt-2">
                        <span className="md:text-lg text-medium">
                          Price: ₹{product.discountPrice}
                        </span>
                      </div>
                      <div className="flex flex-row items-center gap-2 md:gap-4 pt-2 ">
                        <label
                          className="md:text-lg text-sm flex-shrink-0"
                          htmlFor="color-select"
                        >
                          Choose a Color
                        </label>
                        <select
                          id="color-select"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full md:w-auto p-2.5"
                          value={selectedColor}
                          onChange={(e) => setSelectedColor(e.target.value)}
                        >
                          {colorOptions[product.category]?.map((color) => (
                            <option key={color.value} value={color.value}>
                              {color.label}
                            </option>
                          )) || <option value="">No colors available</option>}
                        </select>
                      </div>

                      <div className="flex flex-col gap-2 pt-2 pb-5">
                        <label className="md:text-lg text-sm">
                          Choose an Option For Customized
                        </label>
                        <div className="flex items-center gap-4">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="option"
                              value="upload-file"
                              onChange={() => setSelectedOption("upload-file")}
                              checked={selectedOption === "upload-file"}
                              className="mr-2"
                            />
                            Upload File
                          </label>
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="option"
                              value="enter-name"
                              onChange={() => setSelectedOption("enter-name")}
                              checked={selectedOption === "enter-name"}
                              className="mr-2"
                            />
                            Enter Name
                          </label>
                        </div>

                        {selectedOption === "upload-file" && (
                          <div className="flex flex-row items-center gap-2 md:gap-4 pt-4">
                            <label
                              className="md:text-lg text-sm flex-shrink-0"
                              htmlFor="logo-upload"
                            >
                              Upload Logo
                            </label>
                            <input
                              type="file"
                              id="logo-upload"
                              accept="image/*"
                              onChange={handleLogoUpload}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-auto p-2.5"
                            />
                          </div>
                        )}

                        {selectedOption === "enter-name" && (
                          <div className="flex flex-col gap-2 pt-4">
                            <label
                              className="md:text-lg text-sm"
                              htmlFor="customized-name"
                            >
                              Enter a Customized Name
                            </label>
                            <input
                              id="customized-name"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
                              type="text"
                              value={logoName}
                              onChange={(e) => setLogoName(e.target.value)}
                            />
                          </div>
                        )}
                      </div>

                      <div className="flex items-center mt-2 md:mt-5 border p-1 border-black w-36">
                        <button
                          type="button"
                          className=" rounded-md py-1 px-2 md:py-2 md:px-4 mr-2"
                          onClick={handleDecrement}
                        >
                          <FiMinus />
                        </button>
                        <span className="text-center w-8">{quantity}</span>
                        <button
                          type="button"
                          className=" rounded-md py-1 px-2 md:py-2 md:px-3 ml-2"
                          onClick={handleIncrement}
                        >
                          <FiPlus />
                        </button>
                      </div>

                      <button
                        className="border-black border text-black py-2 px-4  md:w-72 w-full uppercase font-bold"
                        onClick={handleAddToCart}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-4">No products in cart.</div>
                )}
              </div>
              <div className="md:p-0 p-6">
                <div className="border p-4">
                  <h2 className="text-lg font-semibold mb-4  border-b p-1">
                    Summary
                  </h2>
                  <div className="flex justify-between mb-2">
                    <span>Subtotal</span>
                    <span>₹{subtotal}</span>
                  </div>

                  <div className="flex justify-between mb-2">
                    <span>Total (including all taxes)</span>
                    <span className="text-red-500">₹{total}</span>
                  </div>
                </div>
              </div>
              <div className=" p-3 block md:hidden">
                <p className="font-semibold">About this Product:-</p>
                <ul className="p-2">
                  <li>• Lively Sound with Upbeat Bass</li>
                  <li>• Rich and Premium Angular earbuds</li>
                  <li>• Intuitive Touch Controls for Calls,Track & Volume </li>
                  <li>• Description of the fourth feature.</li>
                  <li>• Sweet Resistant - IPX4 rating</li>
                  <li>• Next Gen Type-C port for last charging</li>
                  <li>• Dual Support: Single Earbud & Double as TWS</li>
                  <li>• Voice Assistant (on default OS)</li>
                </ul>
              </div>
              <div>
                <p className="font-medium text-lg mt-5 px-5">
                  {" "}
                  Features & Details
                </p>

                <div className="flex md:gap-8  p-5">
                  <div className="border border-dashed border-gray-200 p-1 w-32 flex flex-col justify-center items-center">
                    <img
                      src="/delivery.png"
                      alt="delivery"
                      className="md:h-16 h-14  mx-auto p-1"
                    />
                    <p className="text-sm">Free Delivery</p>
                  </div>
                  <div className="border border-dashed border-gray-200 p-1 w-32 flex flex-col justify-center items-center">
                    <img
                      src="/success.png"
                      alt="delivery"
                      className="md:h-16 h-14 mx-auto p-2"
                    />
                    <p className="text-sm">Customized</p>
                  </div>
                  <div className="border border-dashed border-gray-200 p-1 w-32 flex flex-col justify-center items-center">
                    <img
                      src="/money.png"
                      alt="delivery"
                      className="md:h-16 h-14 mx-auto p-1"
                    />
                    <p className="text-sm">Pay On Delivery</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="related-products md:m-0 m-4">
          <h2 className="text-xl font-semibold md:mt-8 mt-3">
            Related Products
          </h2>
          <div className="mb-4 ">{description}</div>
          <div className="flex flex-wrap justify-start gap-8 mt-8">
            <a href="/earbuds">
              <img
                src="/relatedearbuds1.png"
                alt="relatedearbuds1"
                className="md:h-32 md:w-32 h-24 w-24"
              />
            </a>
            <a href="/earbuds">
              <img
                src="/relatedearbuds2.png"
                alt="relatedearbuds2"
                className="md:h-32 md:w-32 h-24 w-24"
              />
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductDetails;