import React, { useState } from 'react'
import { FaPlus, FaMinus } from 'react-icons/fa'; 

const PrivacyPolicy = () => {
    const faqData = [
        {
          question: "Information Gathered by the make it Yours",
          answer: "We offer a variety of earbuds, including wireless, noise-canceling, and sport earbuds to suit your lifestyle."
        },
        {
          question: "Disclosure of Information Gathered by make it yours",
          answer: "You can charge your earbuds using the provided charging case or USB cable. Ensure they are placed correctly in the case for optimal charging."
        },
        {
          question: "Updating Your Information and Contacting Us",
          answer: "Yes, our sport earbuds are designed to stay securely in place during workouts and are resistant to sweat and water."
        },
        {
          question: "Privacy Policy - Wearables",
          answer: "If your charger is not functioning, please check the connection and try a different outlet. If the issue persists, contact our support for assistance."
        },
        {
          question: "Privacy Policy - Hearables",
          answer: "Yes, our charging cables are designed to be universally compatible with most devices that use USB charging."
        }
      ];
    const [openIndex, setOpenIndex] = useState(null);
    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
  return (
    <div>
        <p className='md:ml-24 ml-5 mt-5 mb-5 text-xl font-semibold'>Privacy Policy</p>
       <div className='md:ml-24 md:mr-24 mr-4 ml-4 border md:p-5 p-3 rounded'>
        <p>When you purchase or use our <span className='text-red-600'>Make It Yours♥</span> earbuds, chargers, or cables. we may collect certain information to enhance your experience and ensure the optimal functioning of these devices. This section explains how we handle information related to these products.
        Website to be a safe and enjoyable environment for you.</p>
        <p className='pt-6 pb-6'>This privacy policy primarily describes:</p>
        <ul>
            <li> • the information that make it yours collects through the Website or otherwise, which is hosted and operated from India.

</li>
<li> • the information that make it yours collects from its employees or other third parties, business partners or vendors.
</li>
<li> • the security measures make it yours has in place to protect the information that MakeitYours gathers through the Website or otherwise.

</li>
        </ul>
        <p className='pt-6 pb-6'>This Policy also governs make it yours collection of information by any other means, including through your usage of our services and products or the collection of information by any parent, or subsidiaries of MakeitYours ("Other Entities") during your employment or business association with MakeitYours. The Visitors are encouraged to read this Policy before using the Website. By using the Website, you agree to the terms and conditions of this Policy and the Terms and Conditions, available at MakeitYours-lifestyle.com.

</p>
       </div>
       <div className="faq-section md:pb-7 pb-3">
      <div className="faq-section ">
      <div className="faq-list md:ml-24 md:mr-24 ml-4 mr-4 mt-16 ">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item bg-gray-100 p-2 mb-5 rounded">
            <h3 
              className={`faq-question cursor-pointer md:text-lg text-md font-semibold py-2 flex justify-between items-center pl-2 pr-3 ${openIndex === index ? 'text-red-600' : 'text-black'}`} 
              onClick={() => toggleFAQ(index)}
            >
              <span>{faq.question}</span>
              {openIndex === index ? (
                <FaMinus className="text-gray-600 text-md" />
              ) : (
                <FaPlus className="text-gray-600 text-md" />
              )}
            </h3>
            {openIndex === index && (
              <p className="faq-answer text-gray-700 pl-2 ">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
    </div>
  )
}

export default PrivacyPolicy