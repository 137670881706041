import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const [subtotal, setSubtotal] = useState(0);
  const [billingDetails, setBillingDetails] = useState({
    fullname: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    phone: "",
    totalPrice: 0,
    price: 0,
    logoName: "",
    photo: null,
    bluetooth: "",
  });
  const [paymentMode, setPaymentMode] = useState("Online");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const discount = 0;
  const total = subtotal - discount;
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cart);
    const subtotal = cart.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setSubtotal(subtotal);
  }, []);
  useEffect(() => {
    setBillingDetails((prev) => ({
      ...prev,
      totalPrice: total,
      price: total,
    }));
  }, [total]);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "selectedItemPhotos") {
      setBillingDetails((prev) => ({ ...prev, selectedItemPhotos: files[0] }));
    } else {
      setBillingDetails((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMode(e.target.value);
  };
  const closeModal = () => {
    setShowModal(false);
    if (paymentSuccess) {
      navigate("/earbuds"); // Navigate to Earbuds page on successful payment
    }
    window.scroll(0, 0);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fullname", billingDetails.fullname);
    formData.append("email", billingDetails.email);
    formData.append("address", billingDetails.address);
    formData.append("city", billingDetails.city);
    formData.append("state", billingDetails.state);
    formData.append("pinCode", billingDetails.pinCode);
    formData.append("phone", billingDetails.phone);
    formData.append("totalPrice", billingDetails.totalPrice);
    formData.append("paymentMode", paymentMode);
    formData.append(
      "paymentStatus",
      paymentMode === "Online" ? "Pending" : "COD"
    );
    cartItems.forEach((item, index) => {
      formData.append(`items[${index}][productName]`, item.productName);
      formData.append(`items[${index}][quantity]`, item.quantity);
      formData.append(`items[${index}][price]`, item.price);
      formData.append(`items[${index}][subCategoryName]`, item.subCategoryName);
      formData.append(`items[${index}][color]`, item.color);
      formData.append(
        `items[${index}][totalItemPrice]`,
        item.price * item.quantity
      );
      formData.append(`items[${index}][logoName]`, item.logoName);
      formData.append(`items[${index}][bluetooth]`, item.bluetooth);
      if (item.logoPhoto) {
        formData.append(`items[${index}][photo]`, item.logoPhoto);
      }
    });
    try {
      const response = await axios.post(
        "https://customizedapi.onrender.com/order/add",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        console.log("Order creation response:", response.data);
        if (paymentMode === "Online") {
          const { razorpayOrderId } = response.data.data;
          console.log("Razorpay Order ID:", razorpayOrderId);
          const options = {
            key: "rzp_live_KTkaeOJZBBbKu1",
            amount: billingDetails.totalPrice * 100,
            currency: "INR",
            name: "Your Company",
            description: "Order Payment",
            order_id: razorpayOrderId,
            handler: async function (response) {
              console.log("Razorpay Payment Response:", response);
              try {
                const paymentData = {
                  razorpayOrderId: response.razorpay_order_id,
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpaySignature: response.razorpay_signature,
                };
                console.log("Payment Data:", paymentData);
                const paymentResponse = await axios.post(
                  "https://customizedapi.onrender.com/order/verify",
                  paymentData
                );
                if (paymentResponse.status === 200) {
                  setPaymentSuccess(true);
                  setModalTitle("Payment Success");
                  setModalMessage(
                    "Your payment was successful and your order has been placed!"
                  );
                  setShowModal(true);
                  localStorage.removeItem("cart");
                } else {
                  setPaymentSuccess(false);
                  setModalTitle("Payment Failed");
                  setModalMessage(
                    "Payment verification Failed ! Please try again."
                  );
                }
                setShowModal(true);
              } catch (error) {
                setPaymentSuccess(false);
                console.error("Payment verification error:", error);
                setModalTitle("Verification Error");
                setModalMessage(
                  "Error verifying payment. Please contact support."
                );
                setShowModal(true);
              }
            },
            prefill: {
              name: billingDetails.fullname,
              email: billingDetails.email,
              contact: billingDetails.phone,
            },
            theme: {
              color: "#3399cc",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else {
          setPaymentSuccess(true);
          setModalTitle("Order Placed");
          setModalMessage(
            "Your order has been placed successfully with Cash on Delivery!"
          );
          localStorage.removeItem("cart");
          setCartItems([]);
          setShowModal(true);
        }
      } else {
        setPaymentSuccess(false);
        setModalTitle("Order Failed ! please try again");
        setModalMessage("Error placing order. Please try again.");
        setShowModal(true);
      }
    } catch (error) {
      setPaymentSuccess(false);
      console.error("Order Failed ! please try again :", error);
      if (error.response) {
        setModalTitle("Order Failed ! please try again ");
        setModalMessage(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        setModalTitle("Connection Error");
        setModalMessage(
          "Error: No response from server. Please check your connection."
        );
      } else {
        setModalTitle("Unexpected Error");
        setModalMessage(`Error: ${error.message}`);
      }
      setShowModal(true);
    }
  };
  return (
    <div className="bg-gray-100 min-h-screen py-8">
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold mb-4">Checkout</h1>
        <form
          onSubmit={handleSubmit}
          className="bg-white p-6 rounded-lg shadow-md mb-8"
        >
          <p className="text-2xl pb-6 font-semibold">Billing Details</p>
          <div className="grid grid-cols-1 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                Full Name *
              </label>
              <input
                type="text"
                name="fullname"
                value={billingDetails.fullname}
                onChange={handleInputChange}
                required
                className="w-full border px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Email Address *
              </label>
              <input
                type="email"
                name="email"
                value={billingDetails.email}
                onChange={handleInputChange}
                required
                className="w-full border px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Address *
              </label>
              <input
                type="text"
                name="address"
                value={billingDetails.address}
                onChange={handleInputChange}
                required
                className="w-full border px-3 py-2"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium mb-1">City *</label>
                <input
                  type="text"
                  name="city"
                  value={billingDetails.city}
                  onChange={handleInputChange}
                  required
                  className="w-full border px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  State *
                </label>
                <input
                  type="text"
                  name="state"
                  value={billingDetails.state}
                  onChange={handleInputChange}
                  required
                  className="w-full border px-3 py-2"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  PIN Code *
                </label>
                <input
                  type="text"
                  name="pinCode"
                  value={billingDetails.pinCode}
                  onChange={handleInputChange}
                  required
                  className="w-full border px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Phone *
                </label>
                <input
                  type="text"
                  name="phone"
                  value={billingDetails.phone}
                  onChange={handleInputChange}
                  required
                  className="w-full border px-3 py-2"
                />
              </div>
            </div>

            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="paymentMethod"
                value="Online"
                checked={paymentMode === "Online"}
                onChange={handlePaymentMethodChange}
                className="mr-2"
              />
              <label className="text-sm font-medium">Pay Online</label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="paymentMethod"
                value="Offline"
                checked={paymentMode === "Offline"}
                onChange={handlePaymentMethodChange}
                className="mr-2"
              />
              <label className="text-sm font-medium">Cash On Delivery</label>
            </div>
          </div>
          <div className="bg-gray-100 p-4 mb-6 rounded-md">
            <h2 className="text-lg font-semibold mb-2">Order Summary</h2>
            <p className="mb-2">Subtotal: ₹{subtotal.toFixed(2)}</p>
            <p className="mb-2">Discount: ₹{discount.toFixed(2)}</p>
            <p className="font-semibold">Total: ₹{total.toFixed(2)}</p>
          </div>
          <button
            type="submit"
            className="bg-red-600 text-white px-4 py-2 rounded-md"
          >
            Place Order
          </button>
        </form>
      </div>
      {showModal && (
        <div className="fixed z-10 inset-0  md:m-0 m-5">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-black text-white  shadow-xl p-6 relative">
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-Width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h2 className="text-lg font-semibold mb-4 text-center">
                {modalTitle}
              </h2>
              {paymentSuccess ? (
                <img
                  src="./paymentsucess.png"
                  alt="Payment Success"
                  className="h-16 w-16 mx-auto"
                />
              ) : (
                <img
                  src="./paymentcancel.png"
                  alt="Payment Cancel"
                  className="mb-4 w-20 h-20 mx-auto"
                />
              )}
              <p className="pt-3">{modalMessage}</p>
            </div>
          </div>
          <div className="fixed  bg-gray-600 " onClick={closeModal}></div>
        </div>
      )}
    </div>
  );
};

export default Checkout;