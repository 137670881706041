import React from "react";

const ReturnPolicy = () => {
  return (
    <div>
      <p className="md:ml-24 ml-5 mt-5 mb-5 text-xl font-semibold">
        Return Policy
      </p>
      <div className="md:ml-24 md:mr-24 mr-4 ml-4 border md:p-5 p-3 rounded md:mb-8 mb-3">
        <p>
          At <span className="text-red-600">make it yours</span>, we are pleased
          to offer free shipping for all prepaid orders deliverable to any
          location within the country. Delivery times may vary based on the
          destination once the products are dispatched from our warehouse,
          typically ranging from{" "}
          <span className="font-semibold">4 to 6 days</span>. We kindly request
          that users provide accurate and truthful information during the order
          process to avoid any delays or issues with product delivery.
        </p>

        <p className="pt-6 pb-6">
          make it yours reserves the right to confirm and validate the
          information provided by the user at any time. If any user details are
          found to be inaccurate (in whole or in part), Erabus reserves the
          right to reject the registration and prohibit the user from utilizing
          the services available on this website and other affiliated websites
          without prior notice. In the event of non-delivery due to an error on
          your part (e.g., incorrect name, address, or contact number), any
          additional costs associated with re-delivery will be borne by the user
          placing the order.
        </p>
        <p className="pt-4 pb-4">
          For all deliveries within India, all applicable taxes and duties are
          included in the listed product prices. Once your order is shipped, you
          will receive a tracking number along with a link to our courier
          company in your shipping confirmation email, enabling you to monitor
          your order's status. Please note that delivery may be delayed for
          reasons beyond our control. Erabus shall not be liable for any delays
          or non-delivery of goods due to natural disasters, wars, or any
          circumstances beyond our control.
        </p>
        <p className="">
          <span className="font-semibold">Note:</span> Always record an unboxing
          video while opening the package. It is mandatory to share the unboxing
          video when raising a claim for wrong, empty, or damaged products
          within 24 hours of delivery.
        </p>
      </div>
    </div>
  );
};

export default ReturnPolicy;
