import React, { useEffect, useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FaPenAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

const Charger = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const navigate = useNavigate();
  const [selectedRatings, setSelectedRatings] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://customizedapi.onrender.com/product/get"
        );
        if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data;
          const chargerProducts = data.filter(
            (product) => product.category === "Charger"
          );
          setProducts(chargerProducts);
          setFilteredProducts(chargerProducts);
        } else {
          console.error("Invalid API response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching the products:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleAddToCart = (product) => {
    navigate(`/product/charger/${product._id}`);
    window.scrollTo(0, 0);
  };

  const handleRatingClick = (productId, rating) => {
    setSelectedRatings((prev) => ({ ...prev, [productId]: rating }));
  };

  return (
    <div>
      <Helmet>
        <title>Fast Mobile Chargers – Custom & Reliable | Make It Yours</title>
        <meta
          name="description"
          content="Get fast mobile chargers at Make It Yours. Reliable and personalized charging solutions for all devices, serving Kerala, Gujarat, Mumbai, Bengaluru, Delhi, and India. Shop today!"
        />
        <meta
          name="keyword"
          content="Fast mobile chargers with custom options in Kerala and Gujarat | Reliable personalized mobile chargers for Mumbai and Bengaluru | Custom fast mobile charging solutions in Delhi and Kerala | High-quality mobile chargers delivered across Gujarat and Bengaluru | Buy fast mobile chargers online in Mumbai and Delhi | Personalized and reliable mobile chargers for Kerala and Gujarat | Best custom mobile chargers for fast charging in Bengaluru and Mumbai | Fast charging solutions for mobile devices in Delhi and Kerala | Top-rated custom mobile chargers in Gujarat and Bengaluru | Shop reliable fast mobile chargers for all devices in Mumbai and Delhi"
        />
          <link rel="canonical" href="https://makeitur.com/charger" />
      </Helmet>
      <section>
        <img
          src="./chargerbanner.png"
          alt="Charger Banner"
          className="w-full h-48 sm:h-64 md:h-96"
        />
      </section>
      <section className="bg-gray-100 md:px-24 px-6">
        <div className="container py-14">
          <h1 className="text-3xl text-left font-bold font-poppins pb-8 text-red-500">
            Chargers
            <div className="h-1 w-12 bg-black"></div>
          </h1>

          <div className="grid md:grid-cols-4 md:gap-8 grid-cols-2 gap-4">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => {
                return (
                  <div
                    key={product._id}
                    className="relative bg-white shadow-md rounded-lg overflow-hidden"
                  >
                    <span className="flex gap-1 absolute top-1 right-0 bg-gray-200 text-black md:text-sm text-xs font-bold py-1 px-2 ">
                      Customized <FaPenAlt />
                    </span>

                    <div
                      className="cursor-pointer pt-7 md:pt-4"
                      onClick={() => handleAddToCart(product)}
                    >
                      <img
                        src={product.photo}
                        alt={product.productName}
                        className="h-32 sm:h-48 w-48 sm:w-60 mx-auto object-contain transform transition duration-300 hover:scale-95"
                      />
                    </div>
                    <div className="md:p-2 p-1">
                      <p className="text-xl line-clamp-2 text-center">
                        <span className="text-lg font-bold">
                          {product.productName}
                        </span>
                      </p>
                      <p className="text-sm text-gray-500 text-center">
                        {product.description}
                      </p>
                      <div className="flex items-center justify-center space-x-1 text-center pt-1">
                        {Array.from({ length: 5 }, (_, index) => (
                          <span
                            key={index}
                            onClick={() =>
                              handleRatingClick(product._id, index + 1)
                            }
                            className={`cursor-pointer ${
                              selectedRatings[product._id] &&
                              index < selectedRatings[product._id]
                                ? "text-yellow-500"
                                : "text-gray-400"
                            }`}
                          >
                            <FaStar />
                          </span>
                        ))}
                      </div>

                      <p className="text-lg text-center pt-1">
                        <span className="text-sm text-gray-500 line-through ">
                          ₹{product.price}
                        </span>
                        <span className="text-base font-semibold ml-4">
                          ₹{product.discountPrice}
                        </span>
                      </p>

                      <div className="flex justify-center">
                        <button
                          className="rounded border border-black bg-black text-white hover:bg-white hover:text-black hover:border w-full p-2 font-bold shadow-lg"
                          onClick={() => handleAddToCart(product)}
                        >
                          <AddShoppingCartIcon /> Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="col-span-full text-center text-gray-500">
                No products found in this category.
              </p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Charger;
