import React, { useEffect, useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FaPenAlt } from "react-icons/fa";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const CablePage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const navigate = useNavigate();
  const [selectedRatings, setSelectedRatings] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://customizedapi.onrender.com/product/get"
        );
if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data;
const cableProducts = data.filter(
            (product) => product.category === "Cable"
          );
 setProducts(cableProducts);
          setFilteredProducts(cableProducts);
        } else {
          console.error("Invalid API response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching the products:", error);
      }
    };
fetchProducts();
  }, []);
const handleAddToCart = (product) => {
    navigate(`/product/cable/${product._id}`);
    window.scrollTo(0, 0);
  };
const handleFilterProducts = (subcategory) => {
    setSelectedSubcategory(subcategory);
    if (subcategory === "") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.productName.toLowerCase().includes(subcategory.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };
  const handleRatingClick = (productId, rating) => {
    setSelectedRatings((prev) => ({ ...prev, [productId]: rating }));
  };
return (
    <div>
      <Helmet>
        <title>Durable Mobile Cables – Custom & Stylish | Make It Yours</title>
        <meta
          name="description"
          content="Shop durable, custom mobile cables at Make It Yours. High-quality and stylish options delivered to Kerala, Gujarat, Mumbai, Bengaluru, and across India. Buy now!"
        />
        <meta
          name="keywords"
          content="Durable custom mobile cables in Kerala and Gujarat | High-quality stylish mobile cables delivered to Mumbai and Bengaluru | Buy custom mobile cables online in Delhi and Kerala | Best durable and stylish mobile cables in Gujarat and Bengaluru | Premium custom mobile cables for Mumbai and Delhi | Reliable and fashionable mobile cables available in Kerala and Gujarat | Top-rated durable mobile cables for Bengaluru and Mumbai | Custom mobile cables for style and durability in Delhi and Kerala | Shop high-quality mobile cables across India including Gujarat and Bengaluru | Stylish and durable mobile cables for all devices in Mumbai and Delhi"
        />
          <link rel="canonical" href="https://makeitur.com/cable" />
      </Helmet>
      <section>
        <img
          src="./cablebanner.png"
          alt="CableBanner"
          width="100%"
          height="96"
          className="w-full h-48 sm:h-64 md:h-96"
        />
      </section>
      <section className="bg-gray-50 md:px-24 px-6">
        <div className="container py-14">
          <h1 className="text-3xl text-left font-bold font-poppins pb-8 text-red-500">
            Cables
            <div className="h-1 w-12 bg-black"></div>
          </h1>
          <div className="flex flex-wrap gap-2 sm:gap-4 mb-4 sm:mb-6">
            <button
              className={`px-4 py-2 rounded ${
                selectedSubcategory === ""
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("")}
            >
              All
            </button>
            <button
              className={`px-4 py-2 rounded ${
                selectedSubcategory === "iPhone"
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("iPhone")}
            >
              iPhone
            </button>
            <button
              className={`px-4 py-2 rounded ${
                selectedSubcategory === "c type"
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("c type")}
            >
              C type
            </button>
            <button
              className={`px-4 py-2 rounded ${
                selectedSubcategory === "v8"
                  ? "bg-red-500 text-white"
                  : "bg-white text-black border"
              }`}
              onClick={() => handleFilterProducts("v8")}
            >
              V8
            </button>
          </div>
          <div className="grid md:grid-cols-4 md:gap-8 grid-cols-2 gap-4">
  {filteredProducts.length > 0 ? (
    filteredProducts.map((product, index) => {
      const isOutOfStock = false; // Set this to false to always show "Customized" and "Buy Now"

      return (
        <div key={product._id} className="relative bg-white shadow-md rounded-lg overflow-hidden">
          {/* Always display the Customized label */}
          <span className="flex gap-1 absolute top-1 right-0 bg-gray-200 text-black md:text-sm text-xs font-bold py-1 px-2 ">
            Customized <FaPenAlt />
          </span>

          {/* Clickable Image */}
          <div className="cursor-pointer pt-7 md:pt-4" onClick={() => handleAddToCart(product)}>
            <img
              src={product.photo}
              alt={product.title}
              className="h-32 sm:h-48 w-48 sm:w-60 mx-auto object-contain transform transition duration-300 hover:scale-95"
            />
          </div>

          <div className="md:p-2 p-1">
            <p className="text-xl line-clamp-2 text-center">
              <span className="text-lg font-bold">
                {product.productName}
              </span>
            </p>
            <p className="text-sm text-gray-500 text-center">
              {product.description ? (
                <>
                  <span className="block sm:hidden">
                    {`${product.description.slice(0, 40)}...`}
                  </span>
                  <span className="hidden sm:block md:hidden">
                    {`${product.description.slice(0, 60)}...`}
                  </span>
                  <span className="hidden md:block">
                    {`${product.description.slice(0, 80)}...`}
                  </span>
                </>
              ) : (
                "No description available."
              )}
            </p>

            {/* Star Rating */}
            <div className="flex items-center justify-center space-x-1 text-center pt-1">
              {Array.from({ length: 5 }, (_, index) => (
                <span
                  key={index}
                  onClick={() => handleRatingClick(product._id, index + 1)}
                  className={`cursor-pointer ${
                    selectedRatings[product._id] && index < selectedRatings[product._id]
                      ? "text-yellow-500"
                      : "text-gray-400"
                  }`}
                >
                  <FaStar />
                </span>
              ))}
            </div>

            <p className="text-lg text-center pt-1">
              <span className="text-sm text-gray-500 line-through ">
                ₹{product.price}
              </span>
              <span className="text-base font-semibold ml-4">
                ₹{product.discountPrice}
              </span>
            </p>

            <div className="flex justify-center">
              <button
                className={`rounded border border-black ${
                  isOutOfStock ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-black text-white hover:bg-white hover:text-black hover:border"
                } w-full p-2 font-bold shadow-lg`}
                onClick={() => handleAddToCart(product)}
                disabled={isOutOfStock} // still keep the button disabled if you want to handle stock logic in the future
              >
                {isOutOfStock ? "Out of Stock" : <><AddShoppingCartIcon /> Buy Now</>}
              </button>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <p className="col-span-full text-center text-gray-500">
      No products found in this category.
    </p>
  )}
</div>
        </div>
      </section>
    </div>
  );
};

export default CablePage;